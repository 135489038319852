import * as React from "react";
import {useCallback, useRef, useState} from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import {MIconButton} from "../../../components/@material-extend";
import MenuPopover from "../../../components/common/MenuPopover";
import {FormattedMessage} from "react-intl";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {logout, toggleDarkMode} from "../../../redux/auth/slice";
import {selectDarkMode, selectLoggedIn, selectUser, selectUserProfile} from "../../../redux/auth/selector";
import {useNavigate} from "react-router-dom";
import {urls} from "../../../routes/urls";
import NiceModal from "@ebay/nice-modal-react";
import ShareDialog from "../../../components/dialogs/ShareDialog";
import Button from "@mui/material/Button";
import {useSnackbar} from "notistack";
import Typography from "@mui/material/Typography";
import RoleHidden from "../../../components/common/RoleHidden";
import {roleAdmin} from "../../../constants/roles";
import {selectError, selectSuccess} from "../../../redux/ui/selector";
import {useUpdateEffect} from "react-use";
import LoginDialog from "../../../components/dialogs/LoginDialog";
import useNavigateLoggedIn from "../../../helpers/useNavigateLoggedIn";
import IconMenuItem from "../../../components/common/IconMenuItem";
import AccountCircleOutlined from "@mui/icons-material/AccountCircleOutlined";
import ContactSupportOutlined from "@mui/icons-material/ContactSupportOutlined";
import DarkModeOutlined from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlined from "@mui/icons-material/LightModeOutlined";
import ShareOutlined from "@mui/icons-material/ShareOutlined";
import MonitorWeightOutlined from "@mui/icons-material/MonitorWeightOutlined";
import ScaleOutlined from "@mui/icons-material/ScaleOutlined";

import UserProfileDialog from "../../../components/dialogs/UserProfileDialog";
import useEntityNavigation from "../../../helpers/useEntityNavigation";
import {deviceEntityId} from "../../../entities/device.entity";
import {weighingEntityId} from "../../../entities/weighing.entity";

export default function AccountPopover() {
  const navigate = useNavigate();
  const navigateLoggedIn = useNavigateLoggedIn();
  const {getEntityGridUrl, getEntityCreateUrl} = useEntityNavigation();
  const {enqueueSnackbar} = useSnackbar();

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const userProfile = useAppSelector(selectUserProfile);
  const loggedIn = useAppSelector(selectLoggedIn);
  const darkMode = useAppSelector(selectDarkMode);
  const logoutSuccess = useAppSelector((state) => selectSuccess(state, logout.type));
  const logoutError = useAppSelector((state) => selectError(state, logout.type));

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleUserProfile = useCallback((): void => {
    handleClose();
    NiceModal.show(UserProfileDialog, {});
  }, [handleClose]);

  const handleToggleDarkMode = useCallback((): void => {
    handleClose();
    dispatch(toggleDarkMode());
  }, [handleClose, dispatch]);

  const handleContactUs = useCallback((): void => {
    handleClose();
    navigate(urls.contactUs.url);
  }, [handleClose, navigate]);

  const handleShare = useCallback((): void => {
    handleClose();
    NiceModal.show(ShareDialog, {
      url: window.origin,
      title: <FormattedMessage id="shareWithTheWorld" />,
    });
  }, [handleClose]);

  const handleManageDevices = useCallback((): void => {
    handleClose();
    navigateLoggedIn(getEntityGridUrl(deviceEntityId, false));
  }, [handleClose, navigate, getEntityGridUrl]);

  const handleWeighingTest = useCallback((): void => {
    handleClose();
    navigateLoggedIn(getEntityCreateUrl(weighingEntityId, false));
  }, [handleClose, navigate, getEntityGridUrl]);

  const handleLogin = useCallback(async (): Promise<void> => {
    handleClose();
    NiceModal.show(LoginDialog, {});
  }, [handleClose]);

  const handleLogout = useCallback(async (): Promise<void> => {
    handleClose();
    navigate(urls.home.url);
    dispatch(logout());
  }, [handleClose, navigate, dispatch]);

  useUpdateEffect(() => {
    if (logoutSuccess) {
      enqueueSnackbar(<FormattedMessage id="logoutSuccess" />, {variant: "success"});
    }
  }, [logoutSuccess]);

  useUpdateEffect(() => {
    if (logoutError) {
      enqueueSnackbar(<FormattedMessage id="logoutFailed" />, {variant: "error"});
    }
  }, [logoutError]);

  return (
    <>
      <Tooltip title={<FormattedMessage id="menu" />}>
        <MIconButton ref={anchorRef} onClick={handleOpen} color={open ? "primary" : "default"}>
          <AccountCircleOutlined fontSize={"small"} />
        </MIconButton>
      </Tooltip>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{width: 220}} arrow={false}>
        {user && (
          <>
            <Box onClick={handleUserProfile} sx={{my: 1.5, px: 2.5, cursor: "pointer"}}>
              <Typography variant="subtitle1" noWrap>
                {userProfile?.fullName || <FormattedMessage id="username" />}
              </Typography>
              <Typography variant="body2" sx={{color: "text.secondary"}} noWrap>
                {user?.email}
              </Typography>
            </Box>

            <Divider sx={{my: 1}} />
          </>
        )}

        <IconMenuItem
          labelId={darkMode ? "lightMode" : "darkMode"}
          Icon={darkMode ? LightModeOutlined : DarkModeOutlined}
          onClick={handleToggleDarkMode}
        />
        {/*<IconMenuItem labelId="share" Icon={ShareOutlined} onClick={handleShare} />*/}
        <IconMenuItem labelId="contactUs" Icon={ContactSupportOutlined} onClick={handleContactUs} />

        <RoleHidden role={roleAdmin}>
          <>
            <Divider sx={{my: 1}} />

            <IconMenuItem labelId="manageDevices" Icon={MonitorWeightOutlined} onClick={handleManageDevices} />

            <IconMenuItem labelId="weighingTest" Icon={ScaleOutlined} onClick={handleWeighingTest} />
          </>
        </RoleHidden>

        <Box sx={{p: 2, pt: 1.5}}>
          <Button fullWidth color="inherit" variant="outlined" onClick={loggedIn ? handleLogout : handleLogin}>
            <FormattedMessage id={loggedIn ? "logout" : "signUpOrLogin"} />
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
