import {all, call, delay, fork, put, take, takeEvery, select} from "redux-saga/effects";
import {clearStatus, setErrorStatus, setSuccessStatus, startLoading, stopLoading} from "../ui/slice";
import {
  authenticateEmailPassword,
  authenticateError,
  authenticateSuccess,
  changeLocale,
  logout,
  logoutError,
  logoutSuccess,
  refreshUser,
  toggleDarkMode,
  updateUserProfile,
  updateUserProfileSuccess,
} from "./slice";
import {
  selectDarkMode,
  selectLastRefreshTime,
  selectLoggedIn,
  selectRole,
  selectUser,
  selectUserProfile,
} from "./selector";
import {
  browserLocalPersistence,
  browserSessionPersistence,
  createUserWithEmailAndPassword,
  setPersistence,
  signInWithEmailAndPassword,
  User,
  UserCredential,
} from "firebase/auth";
import {auth, firestore} from "../../firebase/firebase";
import {first, isNil, isString, omit} from "lodash";
import {addDoc, collection, doc, getDocs, query, updateDoc, where} from "firebase/firestore";
import {generateFirestoreConverter} from "../../firebase/FirestoreUtils";
import {UserProfile} from "../../types/internal";
import {omitByDeep} from "../../helpers/LodashUtils";
import {eventChannel} from "redux-saga";
import {EventChannel} from "@redux-saga/core";

const userProfileCollection = "userProfiles";

const getUserProfileAsync = async (userId: string): Promise<UserProfile | undefined> => {
  let userProfile: UserProfile | undefined = undefined;
  const q = query(
    collection(firestore, userProfileCollection).withConverter(generateFirestoreConverter<UserProfile>()),
    where("userIds", "array-contains-any", [userId]),
    where("deleted", "==", false)
  );
  const userProfileDocs = await getDocs(q);
  userProfile = first(userProfileDocs.docs)?.data();

  if (!userProfile) {
    const now = new Date().getTime();
    userProfile = {
      id: "",
      creationTime: now,
      lastUpdateTime: now,
      userIds: [userId],
      deleted: false,
    };

    const addRef = await addDoc(
      collection(firestore, userProfileCollection).withConverter(generateFirestoreConverter<UserProfile>()),
      omitByDeep(userProfile, isNil)
    );
    userProfile = {...userProfile, id: addRef.id};
  }

  return userProfile;
};

const getUpdatedUserProfileAsync = async (
  userId: string,
  userProfile?: UserProfile
): Promise<UserProfile | undefined> => {
  if (userProfile?.id) {
    try {
      const q = query(
        collection(firestore, userProfileCollection).withConverter(generateFirestoreConverter<UserProfile>()),
        where("userIds", "array-contains-any", [userId]),
        where("deleted", "==", false),
        where("lastUpdateTime", ">", userProfile.lastUpdateTime)
      );
      const queryResult = await getDocs(q);
      return first(queryResult.docs)?.data();
    } catch (e) {
      return undefined;
    }
  } else {
    return await getUserProfileAsync(userId);
  }
};

const updateUserProfileAsync = async (userProfile: Partial<UserProfile>): Promise<Partial<UserProfile> | undefined> => {
  if (!userProfile.id) {
    return undefined;
  }

  const now = new Date().getTime();
  const updatedUserProfile: Partial<UserProfile> = omitByDeep(
    omit(
      {
        ...userProfile,
        lastUpdateTime: now,
      },
      ["creationTime", "userIds"]
    ),
    isNil
  );

  const updateRef = doc(firestore, userProfileCollection, userProfile.id).withConverter(
    generateFirestoreConverter<UserProfile>()
  );
  await updateDoc(updateRef, updatedUserProfile);

  return updatedUserProfile;
};

const getAuthenticatedUserRoleAsync = async (): Promise<string | undefined> => {
  let role: string | undefined = undefined;
  try {
    const idTokenResult = await auth.currentUser?.getIdTokenResult();
    const roleResult = idTokenResult?.claims["role"];
    if (roleResult && isString(roleResult)) {
      role = roleResult;
    }
  } catch (e) {}
  return role;
};

const authenticateEmailPasswordAsync = async (
  email: string,
  password: string,
  rememberMe?: boolean
): Promise<{user: User; userProfile?: UserProfile; role?: string} | null> => {
  try {
    let result: UserCredential | undefined = undefined;

    try {
      await setPersistence(auth, rememberMe ? browserLocalPersistence : browserSessionPersistence);
    } catch (e) {
      return null;
    }

    try {
      result = await signInWithEmailAndPassword(auth, email, password);
    } catch (e) {
      try {
        result = await createUserWithEmailAndPassword(auth, email, password);
      } catch (e) {}
    }

    if (!result) {
      return null;
    }

    const user = result.user;
    const userProfile: UserProfile | undefined = await getUserProfileAsync(user.uid);
    const role: string | undefined = await getAuthenticatedUserRoleAsync();

    return {
      user: user.toJSON() as User,
      userProfile: userProfile,
      role: role,
    };
  } catch (e) {}

  return null;
};

function* onAuthenticateEmailPassword({type, payload}: ReturnType<typeof authenticateEmailPassword>) {
  const {email, password, rememberMe} = payload;

  try {
    yield put(startLoading(type));
    yield put(clearStatus(type));

    const result: {user: User; userProfile?: UserProfile; role?: string} | null = yield call(
      authenticateEmailPasswordAsync,
      email,
      password,
      rememberMe
    );
    if (!!result) {
      yield put(authenticateSuccess(result));
      yield put(setSuccessStatus(type));
    } else {
      throw new Error();
    }
  } catch (error) {
    yield put(authenticateError());
    yield put(setErrorStatus(type));
  } finally {
    yield put(stopLoading(type));
  }
}

function* onRefreshUser({type, payload}: ReturnType<typeof refreshUser>) {
  const {force} = payload;

  const loggedIn: boolean = yield select(selectLoggedIn);
  if (!loggedIn) {
    return;
  }

  if (!force) {
    const lastRefreshTime: number = yield select(selectLastRefreshTime);
    const now: number = new Date().getTime();
    if (now - lastRefreshTime < 5_000) {
      return;
    }
  }

  const user: User | undefined = yield select(selectUser);
  if (!user) {
    return;
  }

  try {
    yield put(startLoading(type));
    yield put(clearStatus(type));

    const userProfile: UserProfile | undefined = yield select(selectUserProfile);
    const updatedUserProfile: UserProfile | undefined = yield call(getUpdatedUserProfileAsync, user.uid, userProfile);

    const role: string | undefined = yield select(selectRole);
    const updatedRole: string | undefined = yield call(getAuthenticatedUserRoleAsync);

    yield put(
      authenticateSuccess({
        user: user,
        userProfile: updatedUserProfile || userProfile,
        role: updatedRole || role,
      })
    );
    yield put(setSuccessStatus(type));
  } catch (error) {
    yield put(setErrorStatus(type));
  } finally {
    yield put(stopLoading(type));
  }
}

function* onUpdateUserProfile({type, payload}: ReturnType<typeof updateUserProfile>) {
  const {userProfile: userProfileUpdates} = payload;

  const loggedIn: boolean = yield select(selectLoggedIn);
  if (!loggedIn) {
    return;
  }

  const userProfile: UserProfile | undefined = yield select(selectUserProfile);
  if (!userProfile) {
    return;
  }

  try {
    yield put(startLoading(type));
    yield put(clearStatus(type));

    const result: Partial<UserProfile> | undefined = yield call(updateUserProfileAsync, {
      ...userProfileUpdates,
      id: userProfile.id,
    });

    if (!!result) {
      yield put(updateUserProfileSuccess({userProfile: {...userProfile, ...result}}));
      yield put(setSuccessStatus(type));
    } else {
      throw new Error();
    }
  } catch (error) {
    yield put(authenticateError());
    yield put(setErrorStatus(type));
  } finally {
    yield put(stopLoading(type));
  }
}

function* onChangeLocale({type, payload}: ReturnType<typeof changeLocale>) {
  const {locale} = payload;

  const userProfile: UserProfile | undefined = yield select(selectUserProfile);
  if (!userProfile) {
    return;
  }

  if (userProfile.locale === locale) {
    return;
  }

  try {
    yield put(startLoading(type));
    yield put(updateUserProfile({userProfile: {locale: locale}}));
  } catch (error) {
  } finally {
    yield put(stopLoading(type));
  }
}

function* onToggleDarkMode({type}: ReturnType<typeof toggleDarkMode>) {
  const darkMode: boolean = yield select(selectDarkMode);

  const userProfile: UserProfile | undefined = yield select(selectUserProfile);
  if (!userProfile) {
    return;
  }

  if (userProfile.darkMode === darkMode) {
    return;
  }

  try {
    yield put(startLoading(type));
    yield put(updateUserProfile({userProfile: {darkMode: darkMode}}));
  } catch (error) {
  } finally {
    yield put(stopLoading(type));
  }
}

const logoutAsync = async (): Promise<boolean> => {
  try {
    await auth.signOut();
  } catch (e) {}
  return true;
};

function* onLogout({type}: ReturnType<typeof logout>) {
  try {
    yield put(startLoading(type));
    yield put(clearStatus(type));

    const success: boolean = yield call(logoutAsync);
    if (success) {
      yield put(logoutSuccess());
      yield put(setSuccessStatus(type));
    } else {
      throw new Error();
    }
  } catch (error) {
    yield put(logoutError());
    yield put(setErrorStatus(type));
  } finally {
    yield put(stopLoading(type));
  }
}

let authChannel: EventChannel<{user: User | null}> | undefined = undefined;

function getAuthStateChangedChannel(): EventChannel<{user: User | null}> {
  if (!authChannel) {
    authChannel = eventChannel((emit) => {
      const unsubscribe = auth.onAuthStateChanged((user) => emit({user}));
      return unsubscribe;
    });
  }
  return authChannel;
}

function* watchAuthStateChanged() {
  const channel: EventChannel<{user: User | null}> = yield call(getAuthStateChangedChannel);

  while (true) {
    const {user} = yield take(channel);
    if (!user) {
      // Delay is required for the persisted state to load.
      yield delay(250);

      const loggedIn: boolean = yield select(selectLoggedIn);
      if (loggedIn) {
        yield put(logout());
      }
    }
  }
}

export function* watchAuthenticateEmailPassword() {
  yield takeEvery(authenticateEmailPassword, onAuthenticateEmailPassword);
}

export function* watchRefreshUser() {
  yield takeEvery(refreshUser, onRefreshUser);
}

export function* watchUpdateUserProfile() {
  yield takeEvery(updateUserProfile, onUpdateUserProfile);
}

export function* watchChangeLocale() {
  yield takeEvery(changeLocale, onChangeLocale);
}

export function* watchToggleDarkMode() {
  yield takeEvery(toggleDarkMode, onToggleDarkMode);
}

export function* watchLogout() {
  yield takeEvery(logout, onLogout);
}

export default function* rootSaga() {
  yield all([
    fork(watchAuthenticateEmailPassword),
    fork(watchRefreshUser),
    fork(watchUpdateUserProfile),
    fork(watchChangeLocale),
    fork(watchToggleDarkMode),
    fork(watchLogout),
    fork(watchAuthStateChanged),
  ]);
}
