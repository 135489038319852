import * as React from "react";
import {Entity, EntityFormComponentProps} from "../../../../types/entity";
import {Device, Weighing, WeighingFilters} from "../../../../types/internal";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import {FormattedMessage, useIntl} from "react-intl";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {useCallback, useEffect, useMemo, useState} from "react";
import NiceModal from "@ebay/nice-modal-react";
import ShareDialog from "../../../dialogs/ShareDialog";
import {urls} from "../../../../routes/urls";
import {
  urlParamCurrency,
  urlParamCurrencyDefaultValue,
  urlParamDate,
  urlParamDevice,
  urlParamWorth,
} from "../../../../constants/urlParams";
import MenuItem from "@mui/material/MenuItem";
import {get} from "lodash";
import {currencies} from "../../../../helpers/currencies";
import {useSnackbar} from "notistack";
import useEntity from "../../../../contexts/entity/hooks/useEntity";
import useEntityCrud from "../../../../contexts/entity-crud/hooks/useEntityCrud";
import {deviceEntityId} from "../../../../entities/device.entity";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

const WeighingForm = ({entity, item, action, disabled}: EntityFormComponentProps<Weighing, WeighingFilters>) => {
  const intl = useIntl();
  const {enqueueSnackbar} = useSnackbar();
  const {getEntity} = useEntity();
  const {getItems} = useEntityCrud();

  const [devices, setDevices] = useState<Device[] | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  const deviceEntity = useMemo<Entity<Device, any>>(() => getEntity(deviceEntityId), []);

  useEffect(() => {
    (async () => {
      const items = await getItems(deviceEntity);
      setLoading(false);
      if (item) {
        setDevices(items);
      } else {
        setError(true);
      }
    })();
  }, []);

  const {control, setValue} = useFormContext<Weighing>();

  const deviceId: string | undefined = useWatch({control: control, name: `deviceId`});

  useEffect(() => {
    if (deviceId) {
      const device = devices?.find((d) => d.id === deviceId);
      if (device) {
        setValue("deviceName", device.name);
      }
    }
  }, [deviceId]);

  const handleShare = useCallback((): void => {
    if (!item?.id) {
      enqueueSnackbar(<FormattedMessage id="saveWeighingToShare" />, {variant: "info"});
      return;
    }

    const weighingUrl = `${window.origin}/#${urls.weighing.url}?${urlParamWorth}=${item.worth}&${urlParamDate}=${
      item.creationTime
    }&${urlParamDevice}=${encodeURIComponent(item.deviceId)}&${urlParamCurrency}=${
      item.currency || urlParamCurrencyDefaultValue
    }`;
    NiceModal.show(ShareDialog, {
      url: weighingUrl,
      title: <FormattedMessage id="shareWeighing" />,
    });
  }, [item]);

  useEffect(() => {
    if (action) {
      switch (action.actionId) {
        case "shareWeighing":
          handleShare();
          break;
      }
    }
  }, [action]);

  return (
    <Card>
      <CardContent>
        {loading && (
          <Box sx={{textAlign: "center"}}>
            <CircularProgress />
          </Box>
        )}

        {error && (
          <Typography paragraph sx={{textAlign: "center", color: "text.primary"}}>
            <FormattedMessage id="errorLoadingData" />
          </Typography>
        )}

        {devices && (
          <Stack spacing={3}>
            <Controller
              name="deviceId"
              rules={{
                required: intl.formatMessage({id: "requiredField"}),
              }}
              control={control}
              render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
                return (
                  <TextField
                    {...field}
                    inputRef={ref}
                    required
                    fullWidth
                    label={intl.formatMessage({id: "device"})}
                    error={invalid}
                    helperText={error?.message}
                    disabled={disabled}
                    select
                    value={field?.value || []}
                  >
                    {devices.map((d) => {
                      return (
                        <MenuItem key={d.id} value={d.id}>
                          {d.name}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                );
              }}
            />

            <Controller
              name="currency"
              rules={{
                required: intl.formatMessage({id: "requiredField"}),
              }}
              control={control}
              render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
                return (
                  <TextField
                    {...field}
                    inputRef={ref}
                    required
                    fullWidth
                    label={intl.formatMessage({id: "currency"})}
                    error={invalid}
                    helperText={error?.message}
                    disabled={disabled}
                    select
                    value={field?.value || []}
                  >
                    {Object.keys(currencies).map((c) => {
                      const currency = get(currencies, c);
                      return (
                        <MenuItem key={c} value={c}>
                          {currency.name}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                );
              }}
            />

            <Controller
              name="worth"
              rules={{
                required: intl.formatMessage({id: "requiredField"}),
              }}
              control={control}
              render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
                return (
                  <TextField
                    {...field}
                    inputRef={ref}
                    required
                    fullWidth
                    label={intl.formatMessage({id: "worth"})}
                    type="number"
                    error={invalid}
                    helperText={error?.message}
                    disabled={disabled}
                  />
                );
              }}
            />

            <Controller
              name="exchangePrice"
              rules={{
                required: intl.formatMessage({id: "requiredField"}),
              }}
              control={control}
              render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
                return (
                  <TextField
                    {...field}
                    inputRef={ref}
                    required
                    fullWidth
                    label={intl.formatMessage({id: "exchangePrice"})}
                    type="number"
                    error={invalid}
                    helperText={error?.message}
                    disabled={disabled}
                  />
                );
              }}
            />

            <Controller
              name="weightInKilos"
              rules={{
                required: intl.formatMessage({id: "requiredField"}),
              }}
              control={control}
              render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
                return (
                  <TextField
                    {...field}
                    inputRef={ref}
                    required
                    fullWidth
                    label={intl.formatMessage({id: "weightInKilos"})}
                    type="number"
                    error={invalid}
                    helperText={error?.message}
                    disabled={disabled}
                  />
                );
              }}
            />

            <Controller
              name="weightInPounds"
              rules={{
                required: intl.formatMessage({id: "requiredField"}),
              }}
              control={control}
              render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
                return (
                  <TextField
                    {...field}
                    inputRef={ref}
                    required
                    fullWidth
                    label={intl.formatMessage({id: "weightInPounds"})}
                    type="number"
                    error={invalid}
                    helperText={error?.message}
                    disabled={disabled}
                  />
                );
              }}
            />
          </Stack>
        )}
      </CardContent>
    </Card>
  );
};

export default WeighingForm;
