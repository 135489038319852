import {Entity, EntityFactory} from "../types/entity";
import {Weighing, WeighingFilters} from "../types/internal";
import {cleanEntityData, cleanSearchString} from "../helpers/EntityUtils";
import WeighingForm from "../components/entity/forms/weighing/WeighingForm";
import ShareOutlined from "@mui/icons-material/ShareOutlined";

export const weighingEntityId = "weighing";

export const weighingEntityFactory: EntityFactory<Weighing, WeighingFilters> = (
  intl
): Entity<Weighing, WeighingFilters> => ({
  id: weighingEntityId,
  titleId: "weighings",
  collection: "weighings",
  persist: false,
  creatable: true,
  deletable: false,
  shareable: false,
  roles: {},
  defaultOrderBy: {field: "lastUpdateTime", direction: "desc"},
  actions: [
    {
      id: "shareWeighing",
      titleId: "share",
      Icon: ShareOutlined,
    },
  ],
  columns: [
    {
      field: "currency",
      headerNameId: "currency",
      sortComparator: (v1, v2) => {
        return (v1 as string).localeCompare(v2 as string, undefined, {numeric: true});
      },
    },
    {
      field: "lastUpdateTime",
      headerNameId: "lastUpdateTime",
      valueFormatter: (params) => {
        try {
          return `${intl.formatDate(params.value as number)} ${intl.formatTime(params.value as number)}`;
        } catch (e) {
          return "";
        }
      },
    },
  ],
  formComponent: WeighingForm,
  getItemTitle: (item) =>
    `${intl.formatMessage({id: "weighing"})}${item.deviceName ? ` - ${item.deviceName}` : ""}${
      item.creationTime ? ` - ${intl.formatDate(item.creationTime)} ${intl.formatTime(item.creationTime)}` : ""
    }`,
  generateEmptyItem: () => ({
    id: "",
    creationTime: 0,
    lastUpdateTime: 0,
    userIds: [],
    deleted: false,
    currency: "USD",
    worth: 0,
    exchangePrice: 1,
    deviceId: "",
    deviceName: "",
    weightInKilos: 0,
    weightInPounds: 0,
  }),
  generateSaveItem: (item, data) => {
    return {
      ...item,
      ...cleanEntityData(data),
    };
  },
  generateCloneItem: (item) => {
    return item;
  },
  generateEmptyFilters: (): WeighingFilters => ({}),
  generateFullFilters: (): WeighingFilters => ({}),
  hasActiveFilters: (filters: WeighingFilters) => false,
  hasEmptyFilters: (filters: WeighingFilters) => false,
  filterItems: (items, filters) => items,
  filterDataItems: (items) => items,
  searchItems: (items, search) => {
    const cleanedSearch = cleanSearchString(search);
    if (!cleanedSearch) {
      return items;
    }
    return items.filter((item) => item.deviceName.toLowerCase().includes(cleanedSearch));
  },
});
