import {Theme} from "@mui/material/styles/createTheme";
import {Components} from "@mui/material/styles/components";

declare module "@mui/material/SvgIcon" {
  interface SvgIconPropsSizeOverrides {
    tiny: true;
    extraSmall: true;
  }
}

export default function SvgIcon(theme: Theme): Components {
  return {
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeSmall: {
          width: 20,
          height: 20,
          fontSize: "inherit",
        },
        fontSizeLarge: {
          width: 32,
          height: 32,
          fontSize: "inherit",
        },
      },
      variants: [
        {
          props: {fontSize: "tiny"},
          style: {
            fontSize: "12px",
          },
        },
        {
          props: {fontSize: "extraSmall"},
          style: {
            fontSize: "16px",
          },
        },
      ],
    },
  };
}
