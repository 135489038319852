import * as React from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {FunctionComponent, useCallback, useState} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import DialogTitleEnhanced from "./DialogTitleEnhanced";
import NiceModal, {NiceModalHocProps, useModal} from "@ebay/nice-modal-react";
import {useSnackbar} from "notistack";
import {Controller, useForm} from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import {authenticateEmailPassword} from "../../redux/auth/slice";
import {selectError, selectLoading, selectSuccess} from "../../redux/ui/selector";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import {EMAIL_REGEX} from "../../constants/regex";
import {useUpdateEffect} from "react-use";
import Box from "@mui/material/Box";
import {useNavigate} from "react-router-dom";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TooltipIcon from "../common/TooltipIcon";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlined from "@mui/icons-material/VisibilityOffOutlined";

export type LoginDialogProps = {
  redirectUrl?: string;
};

type FormValues = {
  email: string;
  password: string;
  rememberMe: boolean;
};

const LoginDialog: FunctionComponent<LoginDialogProps & NiceModalHocProps> = NiceModal.create(({redirectUrl}) => {
  const modal = useModal();
  const intl = useIntl();
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();

  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => selectLoading(state, authenticateEmailPassword.type));
  const authenticateSuccess = useAppSelector((state) => selectSuccess(state, authenticateEmailPassword.type));
  const authenticateError = useAppSelector((state) => selectError(state, authenticateEmailPassword.type));

  const [showSignUp, setShowSignUp] = useState<boolean>(true);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleShowSignUp = useCallback((): void => {
    setShowSignUp((show) => !show);
  }, [setShowPassword]);

  const handleShowPassword = useCallback((): void => {
    setShowPassword((show) => !show);
  }, [setShowPassword]);

  const {handleSubmit, control} = useForm<FormValues>({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const onSubmit = handleSubmit((data): void => {
    dispatch(authenticateEmailPassword({email: data.email, password: data.password, rememberMe: data.rememberMe}));
  });

  useUpdateEffect(() => {
    if (authenticateSuccess) {
      enqueueSnackbar(<FormattedMessage id="loginSuccess" />, {variant: "success"});

      if (redirectUrl) {
        navigate(redirectUrl);
      }

      modal.hide();
    }
  }, [authenticateSuccess]);

  useUpdateEffect(() => {
    if (authenticateError) {
      enqueueSnackbar(<FormattedMessage id="loginFailed" />, {variant: "error"});
    }
  }, [authenticateError]);

  return (
    <Dialog
      open={modal.visible}
      onClose={() => modal.hide()}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitleEnhanced onClose={modal.hide}>
        <FormattedMessage id={showSignUp ? "signUp" : "login"} />
      </DialogTitleEnhanced>
      <DialogContent>
        <form autoComplete="off" noValidate onSubmit={onSubmit}>
          <Stack>
            <Controller
              name="email"
              rules={{
                required: intl.formatMessage({id: "requiredField"}),
                pattern: {value: EMAIL_REGEX, message: intl.formatMessage({id: "invalidEmail"})},
              }}
              control={control}
              defaultValue=""
              render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
                return (
                  <TextField
                    {...field}
                    inputRef={ref}
                    required
                    fullWidth
                    label={intl.formatMessage({id: "email"})}
                    type="email"
                    autoComplete="username"
                    autoFocus
                    inputProps={{
                      maxLength: 50,
                    }}
                    error={invalid}
                    helperText={error?.message}
                  />
                );
              }}
            />

            <Controller
              name="password"
              rules={{required: intl.formatMessage({id: "requiredField"})}}
              control={control}
              defaultValue=""
              render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
                return (
                  <TextField
                    {...field}
                    inputRef={ref}
                    required
                    fullWidth
                    label={intl.formatMessage({id: "password"})}
                    type={showPassword ? "text" : "password"}
                    autoComplete="current-password"
                    inputProps={{
                      maxLength: 30,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleShowPassword} edge="end">
                            {showPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={invalid}
                    helperText={error?.message}
                    sx={{mt: 3}}
                  />
                );
              }}
            />

            <Controller
              name="rememberMe"
              control={control}
              defaultValue={true}
              render={({field}) => {
                return (
                  <Box sx={{mt: 1}}>
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label={
                        <>
                          <FormattedMessage id="rememberMe" />
                          <TooltipIcon tooltip={<FormattedMessage id="rememberMeExplanation" />} />
                        </>
                      }
                    />
                  </Box>
                );
              }}
            />
          </Stack>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="inherit" onClick={handleShowSignUp}>
          <FormattedMessage id={showSignUp ? "alreadyHaveAccount" : "doNotHaveAccount"} />
        </Button>
        <Box sx={{flexGrow: 1}} />
        <Button variant="outlined" color="primary" onClick={modal.hide}>
          <FormattedMessage id="close" />
        </Button>
        <LoadingButton variant="contained" color="primary" onClick={onSubmit} loading={loading}>
          <FormattedMessage id={showSignUp ? "signUp" : "login"} />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
});

export default LoginDialog;
