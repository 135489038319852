import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export const uiSliceName = "ui";

type UiState = {
  loading: string[];
  success: string[];
  error: string[];
};

const initialState: UiState = {loading: [], success: [], error: []};

export const uiSlice = createSlice({
  name: uiSliceName,
  initialState: initialState,
  reducers: {
    startLoading: (state, action: PayloadAction<string>) => {
      return {...state, loading: [...state.loading, action.payload]};
    },
    stopLoading: (state, action: PayloadAction<string>) => {
      return {...state, loading: state.loading.filter((x) => x !== action.payload)};
    },
    setSuccessStatus: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        success: [...state.success, action.payload],
        error: state.error.filter((x) => x !== action.payload),
      };
    },
    setErrorStatus: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        error: [...state.error, action.payload],
        success: state.success.filter((x) => x !== action.payload),
      };
    },
    clearStatus: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        success: state.success.filter((x) => x !== action.payload),
        error: state.error.filter((x) => x !== action.payload),
      };
    },
  },
});

export const {startLoading, stopLoading, setSuccessStatus, setErrorStatus, clearStatus} = uiSlice.actions;

export default uiSlice.reducer;
