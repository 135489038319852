import {combineReducers} from "@reduxjs/toolkit";
import ui from "./ui/slice";
import auth from "./auth/slice";

const createRootReducer = () =>
  combineReducers({
    ui,
    auth,
  });
export default createRootReducer;
