import * as React from "react";
import {ReactNode} from "react";
import {useAppSelector} from "../../redux/hooks";
import {selectRole} from "../../redux/auth/selector";

type RoleHiddenProps = {
  children: ReactNode;
  role: string;
};

export default function RoleHidden({role, children}: RoleHiddenProps) {
  const userRole = useAppSelector(selectRole);

  if (role === userRole) {
    return <>{children}</>;
  }

  return null;
}
