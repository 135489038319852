import {UrlInfo} from "../types/internal";

function asUrlInfos<T extends {[key: string]: UrlInfo}>(arg: T): T {
  return arg;
}

// Also update urls in firebase-messaging-sw.js
export const urls = asUrlInfos({
  // Error Root
  error: {
    url: "/error",
    path: "error",
    titleKey: "error",
  },
  // App Root
  app: {
    url: "/app",
    path: "app",
    titleKey: "homepage",
  },
  home: {
    url: "/app/home",
    path: "home",
    titleKey: "homepage",
  },
  contactUs: {
    url: "/app/contact-us",
    path: "contact-us",
    titleKey: "contactUs",
  },
  comingSoon: {
    url: "/app/coming-soon",
    path: "coming-soon",
    titleKey: "comingSoon",
  },
  weighing: {
    url: "/app/w",
    path: "w",
    titleKey: "weighing",
  },
  // App Admin Root
  entity: {
    url: "/app/manage",
    path: "manage",
    titleKey: "manage",
  },
  entityGrid: {
    url: "/app/manage/grid/:entity",
    path: "grid/:entity",
    titleKey: "manage",
  },
  entityDetails: {
    url: "/app/manage/details/:entity",
    path: "details/:entity",
    titleKey: "details",
  },
  entityDetailsId: {
    url: "/app/manage/details/:entity/:id",
    path: "details/:entity/:id",
    titleKey: "details",
  },
  entityShareId: {
    url: "/app/share/:id",
    path: "share/:id",
    titleKey: "share",
  },
});
