import * as React from "react";
import {Suspense, lazy} from "react";
import {Navigate, Outlet, useRoutes} from "react-router-dom";
import {urls} from "./urls";
import AppLayout from "../layout/app";
import componentLoader from "../helpers/ComponentLoader";
import LoadingCenter from "../components/common/LoadingCenter";
import LogoOnlyLayout from "../layout/LogoOnlyLayout";
import {defaultAppUrl} from "../constants/defaultValues";
import EntityGuard from "./guards/EntityGuard";
import AuthGuard from "./guards/AuthGuard";

const Loadable = (Component: any) => (props: any) => {
  return (
    <Suspense fallback={<LoadingCenter />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <Navigate to={urls.app.url} replace />,
    },

    // App Routes
    {
      path: urls.app.path,
      element: <AppLayout />,
      children: [
        {path: "", element: <Navigate to={defaultAppUrl} replace />},
        {
          path: urls.home.path,
          element: <Home />,
        },
        {
          path: urls.contactUs.path,
          element: <ContactUs />,
        },
        {
          path: urls.comingSoon.path,
          element: <ComingSoon />,
        },
        {
          path: urls.weighing.path,
          element: <Weighing />,
        },
        {
          path: urls.entity.path,
          element: (
            <AuthGuard>
              <EntityGuard>
                <Outlet />
              </EntityGuard>
            </AuthGuard>
          ),
          children: [
            {
              path: urls.entityGrid.path,
              element: <EntityGrid />,
            },
            {
              path: urls.entityDetailsId.path,
              element: <EntityDetails />,
            },
            {
              path: urls.entityDetails.path,
              element: <EntityDetails />,
            },
          ],
        },
        {
          path: urls.entityShareId.path,
          element: <EntityShare />,
        },
      ],
    },

    // General Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        {path: urls.error.path, element: <Error />},
        {path: "*", element: <Navigate to={urls.error.url} replace />},
      ],
    },
    {path: "*", element: <Navigate to={urls.error.url} replace />},
  ]);
}

// IMPORT COMPONENTS

// APP
const Home = Loadable(lazy(() => componentLoader(() => import("../pages/app/home"))));
const ContactUs = Loadable(lazy(() => componentLoader(() => import("../pages/app/contact-us"))));
const ComingSoon = Loadable(lazy(() => componentLoader(() => import("../pages/app/coming-soon"))));
const Weighing = Loadable(lazy(() => componentLoader(() => import("../pages/app/weighing"))));

// ENTITY
const EntityGrid = Loadable(lazy(() => componentLoader(() => import("../pages/app/entity/entity-grid"))));
const EntityDetails = Loadable(lazy(() => componentLoader(() => import("../pages/app/entity/entity-details"))));
const EntityShare = Loadable(lazy(() => componentLoader(() => import("../pages/app/entity/entity-share"))));

// GENERAL

// ERRORS
const Error = Loadable(lazy(() => componentLoader(() => import("../pages/general/error"))));
