import {isArray, isObject, mapValues, omitBy} from "lodash";

export const omitByDeep = (obj: any, cb: (obj: any) => boolean): any => {
  if (isArray(obj)) {
    return obj.map((innerObj) => omitByDeep(innerObj, cb));
  } else if (isObject(obj)) {
    return mapValues(omitBy(obj, cb), (val) => omitByDeep(val, cb));
  } else {
    return obj;
  }
};
