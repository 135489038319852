import {ComponentType, FunctionComponent} from "react";
import MenuItem from "@mui/material/MenuItem";
import {FormattedMessage} from "react-intl";
import * as React from "react";
import {SvgIconProps} from "@mui/material/SvgIcon";

interface IconMenuItemProps {
  labelId: string;
  Icon: ComponentType<SvgIconProps>;
  color?: "inherit" | "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning";
  disabled?: boolean;
  onClick: () => void;
}

const IconMenuItem: FunctionComponent<IconMenuItemProps> = ({labelId, Icon, color, disabled, onClick}) => {
  return (
    <MenuItem
      onClick={onClick}
      // @ts-ignore
      sx={{typography: "body2", py: 1, px: 2.5, color: (theme) => (color ? theme.palette[color].main : undefined)}}
      disabled={disabled}
    >
      <Icon fontSize={"small"} sx={{mr: 2}} />
      <FormattedMessage id={labelId} />
    </MenuItem>
  );
};
export default IconMenuItem;
