import * as React from "react";
import {forwardRef, ReactNode} from "react";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";

interface TooltipIconProps {
  tooltip: string | ReactNode;
}

const TooltipIcon = forwardRef<HTMLDivElement, TooltipIconProps>(({tooltip}, ref) => {
  return (
    <Tooltip title={<div style={{whiteSpace: "pre-line"}}>{tooltip}</div>} arrow={false} placement={"top"} ref={ref}>
      <Box sx={{display: "inline", p: 0.75}}>
        <InfoOutlined color="primary" sx={{fontSize: 16, verticalAlign: "middle"}} />
      </Box>
    </Tooltip>
  );
});

export default TooltipIcon;
