import * as React from "react";
import {PropsWithChildren, useCallback, useEffect, useState} from "react";
import {BaseEntityRO, Entity} from "../../types/entity";
import {firestore} from "../../firebase/firebase";
import {addDoc, collection, doc, getDoc, getDocs, onSnapshot, setDoc, query, Query, where} from "firebase/firestore";
import {generateFirestoreConverter} from "../../firebase/FirestoreUtils";
import {chain, cloneDeep, find, forEach, isEmpty, isEqual, isNil, maxBy, omit, omitBy} from "lodash";
import {omitByDeep} from "../../helpers/LodashUtils";
import {useSnackbar} from "notistack";
import {FormattedMessage} from "react-intl";
import {sortEntityItems} from "../../helpers/EntityUtils";
import {useLocalStorageState} from "@crud-studio/react-crud-core";
import {localStorageKeyEntityCrudData, localStorageKeyEntityDeletionTime} from "../../constants/localStorageKeys";
import useEntity from "../entity/hooks/useEntity";
import {useAppSelector} from "../../redux/hooks";
import {selectLoggedIn, selectUserId} from "../../redux/auth/selector";
import {useEffectOnce, useUpdateEffect} from "react-use";

type EntityTime = {
  [key: string]: {time: number; ids: {[key: string]: number}};
};

export type EntityCrudContextProps = {
  lastChangeTime: EntityTime;
  lastDeletionTime: EntityTime;
  getItems: <EntityRO extends BaseEntityRO, FiltersRO>(
    entityId: Entity<EntityRO, FiltersRO> | string
  ) => Promise<EntityRO[] | undefined>;
  refreshItems: <EntityRO extends BaseEntityRO, FiltersRO>(
    entityId: Entity<EntityRO, FiltersRO> | string
  ) => Promise<void>;
  getItem: <EntityRO extends BaseEntityRO, FiltersRO>(
    entityId: Entity<EntityRO, FiltersRO> | string,
    itemId: string
  ) => Promise<EntityRO | undefined>;
  saveItem: <EntityRO extends BaseEntityRO, FiltersRO>(
    entityId: Entity<EntityRO, FiltersRO> | string,
    item: EntityRO
  ) => Promise<EntityRO | undefined>;
  deleteItem: <EntityRO extends BaseEntityRO, FiltersRO>(
    entityId: Entity<EntityRO, FiltersRO> | string,
    item: EntityRO
  ) => Promise<EntityRO | undefined>;
};

const EntityCrudContext = React.createContext<EntityCrudContextProps>(undefined!);

export interface EntityCrudProviderProps extends PropsWithChildren<any> {}

export const publicUserId = "all";

const EntityCrudProvider = ({children}: EntityCrudProviderProps) => {
  const {entities, getEntity, hasEntityAccess} = useEntity();
  const {enqueueSnackbar} = useSnackbar();

  const loggedIn = useAppSelector(selectLoggedIn);
  const userId = useAppSelector(selectUserId);

  const [data, setData] = useLocalStorageState<{[key: string]: any[]}>(
    localStorageKeyEntityCrudData,
    {},
    {encrypted: false}
  );

  const [lastChangeTime, setLastChangeTime] = useState<EntityTime>({});
  const [lastDeletionTime, setLastDeletionTime] = useLocalStorageState<EntityTime>(
    localStorageKeyEntityDeletionTime,
    {}
  );

  const [snapshotSubscribers, setSnapshotSubscribers] = useState<{
    [key: string]: {
      lastUpdateTime: number;
      unsubscribe: (() => void) | undefined;
      lastDeletionTime: number;
      deletionUnsubscribe: (() => void) | undefined;
    };
  }>({});
  const [snapshotData, setSnapshotData] = useState<{[key: string]: any[]}>({});
  const [snapshotDeletionData, setSnapshotDeletionData] = useState<{[key: string]: any[]}>({});
  const [registerSnapshotEntityIds, setRegisterSnapshotEntityIds] = useState<string[]>([]);

  useEffectOnce(() => {
    entities
      .filter((entity) => entity.persist)
      .forEach((entity) => {
        (async () => {
          try {
            if (hasEntityAccess(entity, "read")) {
              const entityData = data[entity.id];
              if (entityData) {
                await refreshItems(entity);
              } else {
                await loadItems<any, any>(entity);
              }
            } else {
              clearItems(entity);
            }
          } catch (e) {}
        })();
      });
  });

  useUpdateEffect(() => {
    entities
      .filter((entity) => entity.persist)
      .forEach((entity) => {
        (async () => {
          try {
            // TODO: improve to clear/load by user id/all
            clearItems(entity);
            if (hasEntityAccess(entity, "read")) {
              await loadItems<any, any>(entity);
            }
          } catch (e) {}
        })();
      });
  }, [loggedIn]);

  const updateLastTime = useCallback(
    (dispatch: React.Dispatch<React.SetStateAction<EntityTime>>, entity: Entity<any, any>, ids?: string[]): void => {
      if (!entity.persist) {
        return;
      }

      const now = new Date().getTime();
      dispatch((currentLastTime) => {
        const entityLastTime: {time: number; ids: {[key: string]: number}} = {
          time: now,
          ids: {},
        };
        if (ids) {
          ids.forEach((id) => {
            entityLastTime.ids[id] = now;
          });
        }
        return {
          ...currentLastTime,
          [entity.id]: {...entityLastTime},
        };
      });
    },
    []
  );

  const updateLastChangeTime = useCallback(
    (entity: Entity<any, any>, ids?: string[]): void => {
      updateLastTime(setLastChangeTime, entity, ids);
    },
    [updateLastTime, setLastChangeTime]
  );

  const updateLastDeletionTime = useCallback(
    (entity: Entity<any, any>, ids?: string[]): void => {
      updateLastTime(setLastDeletionTime, entity, ids);
    },
    [updateLastTime, setLastDeletionTime]
  );

  const getEntityQuery = useCallback(
    <EntityRO extends BaseEntityRO>(
      entity: Entity<EntityRO, any>,
      deleted: boolean,
      options?: {lastUpdateTime?: number; deletionTime?: number}
    ): Query<EntityRO> => {
      let q = query(
        collection(firestore, entity.collection).withConverter(generateFirestoreConverter<EntityRO>()),
        where("userIds", "array-contains-any", [...(userId ? [userId] : []), "all"]),
        where("deleted", "==", deleted)
      );
      if (!isNil(options?.lastUpdateTime)) {
        q = query(q, where("lastUpdateTime", ">", options?.lastUpdateTime));
      }
      if (!isNil(options?.deletionTime)) {
        q = query(q, where("deletionTime", ">", options?.deletionTime));
      }
      return q;
    },
    [userId]
  );

  useEffect(() => {
    if (isEmpty(snapshotData)) {
      return;
    }

    forEach(snapshotData, (snapshotItems, entityId) => {
      const entity = getEntity(entityId);
      updateData(entity, snapshotItems);
      registerSnapshot(entity);
    });

    setSnapshotData({});
  }, [snapshotData]);

  useEffect(() => {
    if (isEmpty(snapshotDeletionData)) {
      return;
    }

    forEach(snapshotDeletionData, (snapshotDeletedItems, entityId) => {
      const entity = getEntity(entityId);
      updateData(entity, [], snapshotDeletedItems);
      registerSnapshot(entity);
    });

    setSnapshotDeletionData({});
  }, [snapshotDeletionData]);

  const unregisterSnapshot = useCallback(
    (entity: Entity<any, any>): void => {
      if (!entity.persist) {
        return;
      }

      setSnapshotSubscribers((currentSnapshotSubscribers) => {
        const entitySnapshotSubscriber = currentSnapshotSubscribers[entity.id];
        if (entitySnapshotSubscriber) {
          entitySnapshotSubscriber.unsubscribe?.();
        }
        return omitBy(currentSnapshotSubscribers, (value, entityId) => entityId === entity.id);
      });
    },
    [setSnapshotSubscribers]
  );

  const registerSnapshot = useCallback(
    (entity: Entity<any, any>): void => {
      if (!entity.persist) {
        return;
      }

      setRegisterSnapshotEntityIds((currentIds) => [...currentIds.filter((id) => id !== entity.id), entity.id]);
    },
    [setRegisterSnapshotEntityIds]
  );

  useEffect(() => {
    if (isEmpty(registerSnapshotEntityIds)) {
      return;
    }

    registerSnapshotEntityIds.forEach((entityId) => {
      const entity = getEntity(entityId);
      registerSnapshotInternal(entity);
    });

    setRegisterSnapshotEntityIds([]);
  }, [registerSnapshotEntityIds]);

  const registerSnapshotInternal = useCallback(
    <EntityRO extends BaseEntityRO, FiltersRO>(entity: Entity<EntityRO, FiltersRO>): void => {
      if (!entity.persist) {
        return;
      }

      const items: EntityRO[] | undefined = data[entity.id];
      const lastUpdateTime: number = items && !isEmpty(items) ? maxBy(items, "lastUpdateTime")?.lastUpdateTime || 0 : 0;

      const snapshotSubscriber = snapshotSubscribers[entity.id];
      let unsubscribe: (() => void) | undefined = snapshotSubscriber?.unsubscribe;
      if (!snapshotSubscriber || snapshotSubscriber.lastUpdateTime < lastUpdateTime) {
        snapshotSubscriber?.unsubscribe?.();

        const query = getEntityQuery(entity, false, {lastUpdateTime: lastUpdateTime});
        unsubscribe = onSnapshot(query, (querySnapshot) => {
          if (querySnapshot.metadata.hasPendingWrites) {
            return;
          }

          const snapshotItems: EntityRO[] = [];
          querySnapshot.forEach((doc) => {
            snapshotItems.push(doc.data());
          });

          setSnapshotData((currentSnapshotData) => ({...currentSnapshotData, [entity.id]: snapshotItems}));
        });
      }

      let deletionUnsubscribe: (() => void) | undefined = snapshotSubscriber?.deletionUnsubscribe;
      const snapshotDeletionTime: number = lastDeletionTime[entity.id]?.time || 0;
      if (!snapshotSubscriber || snapshotSubscriber.lastDeletionTime < snapshotDeletionTime) {
        snapshotSubscriber?.deletionUnsubscribe?.();

        const query = getEntityQuery(entity, true, {deletionTime: snapshotDeletionTime});
        deletionUnsubscribe = onSnapshot(query, (querySnapshot) => {
          if (querySnapshot.metadata.hasPendingWrites) {
            return;
          }

          const snapshotItems: EntityRO[] = [];
          querySnapshot.forEach((doc) => {
            snapshotItems.push(doc.data());
          });

          setSnapshotDeletionData((currentSnapshotDeletionData) => ({
            ...currentSnapshotDeletionData,
            [entity.id]: snapshotItems,
          }));
        });
      }

      setSnapshotSubscribers((currentSnapshotSubscribers) => ({
        ...currentSnapshotSubscribers,
        [entity.id]: {
          lastUpdateTime: lastUpdateTime,
          unsubscribe: unsubscribe,
          lastDeletionTime: snapshotDeletionTime,
          deletionUnsubscribe: deletionUnsubscribe,
        },
      }));
    },
    [data, snapshotSubscribers, setSnapshotSubscribers, getEntityQuery, setSnapshotData]
  );

  const updateData = useCallback(
    async <EntityRO extends BaseEntityRO>(
      entity: Entity<EntityRO, any>,
      items: EntityRO[],
      deletedItems?: EntityRO[],
      isAll?: boolean
    ): Promise<void> => {
      if (!entity.persist) {
        return;
      }

      if (!isAll) {
        if (isEmpty(items) && isEmpty(deletedItems || [])) {
          return;
        }

        const dataItems: EntityRO[] | undefined = data[entity.id];
        if (!dataItems) {
          return;
        }
      }

      setData((currentData) => {
        const updatedItems = sortEntityItems(
          entity,
          isAll
            ? items
            : chain(currentData[entity.id] || [])
                .filter(
                  (currentItem) =>
                    !find(items, (item) => item.id === currentItem.id) &&
                    !find(deletedItems, (deletedItem) => deletedItem.id === currentItem.id)
                )
                .push(...items)
                .value()
        );
        return {...currentData, [entity.id]: updatedItems};
      });

      updateLastChangeTime(entity, isAll ? undefined : items.map((item) => item.id));
      if (isAll || deletedItems) {
        updateLastDeletionTime(entity, isAll ? undefined : deletedItems?.map((deletedItem) => deletedItem.id));
      }

      registerSnapshot(entity);
    },
    [data, setData, updateLastChangeTime, registerSnapshot]
  );

  const clearItems = useCallback(
    (entity: Entity<any, any>): void => {
      if (!entity.persist) {
        return;
      }

      setData((currentData) => omitBy(currentData, (value, key) => key === entity.id));

      // updateLastChangeTime(entity);

      unregisterSnapshot(entity);
    },
    [setData, unregisterSnapshot]
  );

  const refreshItems = useCallback(
    async <EntityRO extends BaseEntityRO, FiltersRO>(entityId: Entity<EntityRO, FiltersRO> | string): Promise<void> => {
      const entity = getEntity(entityId);

      if (!entity.persist) {
        return;
      }

      if (!hasEntityAccess(entity, "read")) {
        return;
      }

      const items: EntityRO[] | undefined = data[entity.id];
      const lastUpdateTime: number = items && !isEmpty(items) ? maxBy(items, "lastUpdateTime")?.lastUpdateTime || 0 : 0;
      const query = getEntityQuery(entity, false, {lastUpdateTime: lastUpdateTime});
      const result = await getDocs(query);
      const resultItems = result.docs.map<EntityRO>((doc) => doc.data());

      const queryDeletionTime: number = lastDeletionTime[entity.id]?.time || 0;
      const deletionQuery = getEntityQuery(entity, true, {deletionTime: queryDeletionTime});
      const deletionResult = await getDocs(deletionQuery);
      const deletionResultItems = deletionResult.docs.map<EntityRO>((doc) => doc.data());

      updateData(entity, resultItems, deletionResultItems);

      registerSnapshot(entity);
    },
    [data, hasEntityAccess, getEntityQuery, updateData, registerSnapshot, lastDeletionTime]
  );

  const loadItems = useCallback(
    async <EntityRO extends BaseEntityRO, FiltersRO>(
      entity: Entity<EntityRO, FiltersRO>
    ): Promise<EntityRO[] | undefined> => {
      if (!hasEntityAccess(entity, "read")) {
        return undefined;
      }

      const query = getEntityQuery(entity, false);
      const result = await getDocs(query);
      const resultItems = sortEntityItems(
        entity,
        result.docs.map<EntityRO>((doc) => doc.data())
      );

      updateData(entity, resultItems, [], true);

      return resultItems;
    },
    [setData, updateLastChangeTime, hasEntityAccess, getEntityQuery, updateData]
  );

  const getItems = useCallback(
    async <EntityRO extends BaseEntityRO, FiltersRO>(
      entityId: Entity<EntityRO, FiltersRO> | string
    ): Promise<EntityRO[] | undefined> => {
      const entity = getEntity(entityId);

      if (!hasEntityAccess(entity, "read")) {
        return undefined;
      }

      let resultItems: EntityRO[] | undefined = data[entity.id];
      if (resultItems) {
        return resultItems;
      }

      try {
        resultItems = await loadItems(entity);
      } catch (e) {
        enqueueSnackbar(<FormattedMessage id="networkError" />, {variant: "error"});
      }

      return resultItems;
    },
    [data, hasEntityAccess]
  );

  const getItem = useCallback(
    async <EntityRO extends BaseEntityRO, FiltersRO>(
      entityId: Entity<EntityRO, FiltersRO> | string,
      itemId: string
    ): Promise<EntityRO | undefined> => {
      const entity = getEntity(entityId);

      if (!hasEntityAccess(entity, "read")) {
        return undefined;
      }

      let resultItem: EntityRO | undefined = data[entity.id]?.find((item) => item.id === itemId);
      if (resultItem) {
        return cloneDeep(resultItem);
      }

      try {
        const ref = doc(firestore, entity.collection, itemId).withConverter(generateFirestoreConverter<EntityRO>());
        const result = await getDoc(ref);
        resultItem = result.data();
      } catch (e) {
        enqueueSnackbar(<FormattedMessage id="networkError" />, {variant: "error"});
      }

      if (resultItem?.deleted) {
        return undefined;
      }

      if (resultItem && resultItem.userIds?.some((id) => [userId, publicUserId].includes(id))) {
        updateData(entity, [resultItem]);
      }

      return cloneDeep(resultItem);
    },
    [data, getEntity, hasEntityAccess, updateData, userId]
  );

  const saveItem = useCallback(
    async <EntityRO extends BaseEntityRO, FiltersRO>(
      entityId: Entity<EntityRO, FiltersRO> | string,
      item: EntityRO
    ): Promise<EntityRO | undefined> => {
      const entity = getEntity(entityId);

      if (!hasEntityAccess(entity, "write")) {
        return undefined;
      }

      if (item.id) {
        const currentItem = await getItem(entity, item.id);
        if (isEqual(omit(currentItem, ["lastUpdateTime"]), omit(item, ["lastUpdateTime"]))) {
          return item;
        }
      }

      let resultItem: EntityRO | undefined = undefined;
      try {
        const now = new Date().getTime();
        item.lastUpdateTime = now;
        if (item.id) {
          const setRef = doc(firestore, entity.collection, item.id).withConverter(
            generateFirestoreConverter<EntityRO>()
          );
          await setDoc(setRef, omitByDeep(item, isNil));
          resultItem = item;
        } else {
          item.creationTime = now;
          item.deleted = false;
          if (isEmpty(item.userIds)) {
            item.userIds.push(userId);
          }
          const addRef = await addDoc(
            collection(firestore, entity.collection).withConverter(generateFirestoreConverter<EntityRO>()),
            omitByDeep(item, isNil)
          );
          resultItem = {...item, id: addRef.id};
        }
      } catch (e) {
        enqueueSnackbar(<FormattedMessage id="networkError" />, {variant: "error"});
      }

      if (resultItem && resultItem.userIds?.some((id) => [userId, publicUserId].includes(id))) {
        updateData(entity, [resultItem]);
      }

      return cloneDeep(resultItem);
    },
    [hasEntityAccess, getItem, updateData, userId]
  );

  const deleteItem = useCallback(
    async <EntityRO extends BaseEntityRO, FiltersRO>(
      entityId: Entity<EntityRO, FiltersRO> | string,
      item: EntityRO
    ): Promise<EntityRO | undefined> => {
      const entity = getEntity(entityId);

      if (!hasEntityAccess(entity, "write")) {
        return undefined;
      }

      if (!item.id) {
        return undefined;
      }

      let resultItem: EntityRO | undefined = undefined;
      try {
        const now = new Date().getTime();
        item.deletionTime = now;
        item.deleted = true;
        const setRef = doc(firestore, entity.collection, item.id).withConverter(generateFirestoreConverter<EntityRO>());
        await setDoc(setRef, omitByDeep(item, isNil));
        resultItem = item;
      } catch (e) {
        enqueueSnackbar(<FormattedMessage id="networkError" />, {variant: "error"});
      }

      if (resultItem && resultItem.userIds?.some((id) => [userId, publicUserId].includes(id))) {
        updateData(entity, [], [resultItem]);
      }

      return cloneDeep(resultItem);
    },
    [hasEntityAccess, getItem, updateData, userId]
  );

  return (
    <EntityCrudContext.Provider
      value={{lastChangeTime, lastDeletionTime, getItems, refreshItems, getItem, saveItem, deleteItem}}
    >
      {children}
    </EntityCrudContext.Provider>
  );
};

export {EntityCrudContext, EntityCrudProvider};
