import * as React from "react";
import {alpha} from "@mui/system/colorManipulator";
import styled from "@mui/material/styles/styled";
import Popover from "@mui/material/Popover";
import {PopoverProps} from "@mui/material/Popover";

const ArrowStyle = styled("span")(({theme}) => ({
  top: -7,
  zIndex: 1,
  width: 12,
  right: 20,
  height: 12,
  content: "''",
  position: "absolute",
  borderRadius: "0 0 4px 0",
  transform: "rotate(-135deg)",
  background: theme.palette.background.paper,
  borderRight: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
  borderBottom: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
}));

export default function MenuPopover({children, sx, arrow, ...other}: PopoverProps & {arrow?: boolean}) {
  return (
    <Popover
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      PaperProps={{
        sx: {
          ...(arrow ? {mt: 1.5, ml: 1} : {}),
          overflow: "inherit",
          boxShadow: (theme) => theme.customShadows.z20,
          border: (theme) => `solid 1px ${theme.palette.grey[500_8]}`,
          // width: 200,
          ...sx,
        },
      }}
      {...other}
    >
      {arrow && <ArrowStyle />}

      {children}
    </Popover>
  );
}
