import {FunctionComponent, useEffect} from "react";
import {isNil} from "lodash";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {selectDarkMode, selectLocale, selectLoggedIn, selectUserProfile} from "../redux/auth/selector";
import {changeLocale, toggleDarkMode} from "../redux/auth/slice";
import {activeLocales} from "../constants/defaultValues";

interface IProps {}

const UserProfileManager: FunctionComponent<IProps> = () => {
  const dispatch = useAppDispatch();
  const loggedIn = useAppSelector(selectLoggedIn);
  const userProfile = useAppSelector(selectUserProfile);
  const locale = useAppSelector(selectLocale);
  const darkMode = useAppSelector(selectDarkMode);

  useEffect(() => {
    if (loggedIn && userProfile) {
      if (!isNil(userProfile.darkMode) && userProfile.darkMode !== darkMode) {
        dispatch(toggleDarkMode());
      }
      if (!isNil(userProfile.locale) && userProfile.locale !== locale && activeLocales.includes(userProfile.locale)) {
        dispatch(changeLocale({locale: userProfile.locale}));
      }
    }
  }, [loggedIn]);

  return null;
};
export default UserProfileManager;
