// @ts-nocheck

import {DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions} from "firebase/firestore";
import {publicUserId} from "../contexts/entity-crud/EntityCrudContext";
import {isNil} from "lodash";

export const generateFirestoreConverter = <EntityRO>(): FirestoreDataConverter<EntityRO> => {
  return {
    toFirestore(item: EntityRO): DocumentData {
      // TODO: Clean at some point
      if (!item.userIds) {
        if (item.userId) {
          item.userIds = [item.userId];
        } else {
          item.userIds = [publicUserId];
        }
      }
      if (isNil(item.deleted)) {
        item.deleted = false;
      }

      return item;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): EntityRO {
      const data = snapshot.data(options)!;
      data.id = snapshot.id;

      // TODO: Clean at some point
      if (!data.userIds) {
        if (data.userId) {
          data.userIds = [data.userId];
        } else {
          data.userIds = [publicUserId];
        }
      }
      if (isNil(data.deleted)) {
        data.deleted = false;
      }

      return data;
    },
  };
};
