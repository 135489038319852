import * as React from "react";
import {FunctionComponent} from "react";
import {FormattedMessage} from "react-intl";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import styled from "@mui/material/styles/styled";
import MaintenanceIllustration from "../../components/svg/MaintenanceIllustration";

const RootStyle = styled(Box)(({theme}) => ({
  display: "flex",
  minHeight: "100%",
  alignItems: "center",
  paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(10),
}));

interface IProps {}

const Maintenance: FunctionComponent<IProps> = (props: IProps) => {
  return (
    <RootStyle>
      <Container>
        <Box sx={{maxWidth: 480, margin: "auto", textAlign: "center"}}>
          <Typography variant="h3" paragraph>
            <FormattedMessage id="underMaintenance" />
          </Typography>
          <Typography sx={{color: "text.secondary"}}>
            <FormattedMessage id="underMaintenanceGetBackSoon" />
          </Typography>

          <MaintenanceIllustration sx={{height: 260, my: {xs: 3, sm: 6}}} />
        </Box>
      </Container>
    </RootStyle>
  );
};

export default Maintenance;
