import * as React from "react";
import {FunctionComponent, PropsWithChildren, SetStateAction, useCallback, useState} from "react";
import {useLocalStorageState} from "@crud-studio/react-crud-core";
import {localStorageKeyEntityFilters} from "../../constants/localStorageKeys";
import useEntity from "../entity/hooks/useEntity";
import {BaseEntityRO, Entity} from "../../types/entity";
import {isFunction} from "lodash";

export type EntityFiltersContextProps = {
  filtersChangeFlag: number;
  getFilters: <EntityRO extends BaseEntityRO, FiltersRO>(entityId: Entity<EntityRO, FiltersRO> | string) => FiltersRO;
  setFilters: <EntityRO extends BaseEntityRO, FiltersRO>(
    entityId: Entity<EntityRO, FiltersRO> | string,
    filters: SetStateAction<FiltersRO>
  ) => void;
  clearFilters: <EntityRO extends BaseEntityRO, FiltersRO>(entityId: Entity<EntityRO, FiltersRO> | string) => void;
  emptyFilters: <EntityRO extends BaseEntityRO, FiltersRO>(entityId: Entity<EntityRO, FiltersRO> | string) => void;
  hasActiveFilters: <EntityRO extends BaseEntityRO, FiltersRO>(
    entityId: Entity<EntityRO, FiltersRO> | string
  ) => boolean;
  hasEmptyFilters: <EntityRO extends BaseEntityRO, FiltersRO>(
    entityId: Entity<EntityRO, FiltersRO> | string
  ) => boolean;
  filterItems: <EntityRO extends BaseEntityRO, FiltersRO>(
    entityId: Entity<EntityRO, FiltersRO> | string,
    items: EntityRO[]
  ) => EntityRO[];
  searchItems: <EntityRO extends BaseEntityRO, FiltersRO>(
    entityId: Entity<EntityRO, FiltersRO> | string,
    items: EntityRO[],
    search: string
  ) => EntityRO[];
};

const EntityFiltersContext = React.createContext<EntityFiltersContextProps>(undefined!);

export interface EntityFiltersProviderProps extends PropsWithChildren<any> {}

const EntityFiltersProvider: FunctionComponent<EntityFiltersProviderProps> = ({children}) => {
  const {entities, getEntity} = useEntity();

  const [filtersMap, setFiltersMap] = useLocalStorageState<{[key: string]: any}>(
    localStorageKeyEntityFilters,
    (): {[key: string]: any} => {
      const filters: {[key: string]: any} = {};
      entities
        .filter((entity) => !entity.filtersId)
        .forEach((entity) => (filters[entity.id] = entity.generateFullFilters()));
      return filters;
    },
    {encrypted: false}
  );
  const [filtersChangeFlag, setFiltersChangeFlag] = useState<number>(0);

  const getEntityFiltersId = useCallback((entity: Entity<any, any>): string => {
    return entity.filtersId || entity.id;
  }, []);

  const getFiltersInternal = useCallback(
    <EntityRO extends BaseEntityRO, FiltersRO>(
      entityId: Entity<EntityRO, FiltersRO> | string,
      filtersMap: {[key: string]: any}
    ): FiltersRO => {
      const entity = getEntity(entityId);
      const entityFilters = filtersMap[getEntityFiltersId(entity)];
      return entityFilters || entity.generateFullFilters();
    },
    []
  );

  const getFilters = useCallback(
    <EntityRO extends BaseEntityRO, FiltersRO>(entityId: Entity<EntityRO, FiltersRO> | string): FiltersRO => {
      return getFiltersInternal(entityId, filtersMap);
    },
    [filtersMap, getFiltersInternal]
  );

  const setFilters = useCallback(
    <EntityRO extends BaseEntityRO, FiltersRO>(
      entityId: Entity<EntityRO, FiltersRO> | string,
      filters: SetStateAction<FiltersRO>
    ): void => {
      const entity = getEntity(entityId);
      setFiltersMap((currentFiltersMap) => {
        currentFiltersMap[getEntityFiltersId(entity)] = isFunction(filters)
          ? filters(getFiltersInternal(entity, currentFiltersMap))
          : filters;
        return currentFiltersMap;
      });
      setFiltersChangeFlag((currentFiltersChangeFlag) => currentFiltersChangeFlag + 1);
    },
    [setFiltersMap, setFiltersChangeFlag, getFiltersInternal, getEntity]
  );

  const clearFilters = useCallback(
    <EntityRO extends BaseEntityRO, FiltersRO>(entityId: Entity<EntityRO, FiltersRO> | string): void => {
      const entity = getEntity(entityId);
      setFilters(entity, entity.generateFullFilters());
    },
    [setFilters]
  );

  const emptyFilters = useCallback(
    <EntityRO extends BaseEntityRO, FiltersRO>(entityId: Entity<EntityRO, FiltersRO> | string): void => {
      const entity = getEntity(entityId);
      setFilters(entity, entity.generateEmptyFilters());
    },
    [setFilters]
  );

  const hasActiveFilters = useCallback(
    <EntityRO extends BaseEntityRO, FiltersRO>(entityId: Entity<EntityRO, FiltersRO> | string): boolean => {
      const entity = getEntity(entityId);
      return entity.hasActiveFilters(getFilters(entity));
    },
    [getFilters]
  );

  const hasEmptyFilters = useCallback(
    <EntityRO extends BaseEntityRO, FiltersRO>(entityId: Entity<EntityRO, FiltersRO> | string): boolean => {
      const entity = getEntity(entityId);
      return entity.hasEmptyFilters(getFilters(entity));
    },
    [getFilters]
  );

  const filterItems = useCallback(
    <EntityRO extends BaseEntityRO, FiltersRO>(
      entityId: Entity<EntityRO, FiltersRO> | string,
      items: EntityRO[]
    ): EntityRO[] => {
      const entity = getEntity(entityId);
      return entity.filterItems(items, getFilters(entity));
    },
    [getFilters]
  );

  const searchItems = useCallback(
    <EntityRO extends BaseEntityRO, FiltersRO>(
      entityId: Entity<EntityRO, FiltersRO> | string,
      items: EntityRO[],
      search: string
    ): EntityRO[] => {
      const entity = getEntity(entityId);
      return entity.searchItems(items, search);
    },
    [getFilters]
  );

  return (
    <EntityFiltersContext.Provider
      value={{
        filtersChangeFlag,
        getFilters,
        setFilters,
        clearFilters,
        emptyFilters,
        hasActiveFilters,
        hasEmptyFilters,
        filterItems,
        searchItems,
      }}
    >
      {children}
    </EntityFiltersContext.Provider>
  );
};

export {EntityFiltersContext, EntityFiltersProvider};
