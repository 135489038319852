import * as React from "react";
import {useState} from "react";
import {useSnackbar} from "notistack";
import {CopyToClipboard} from "react-copy-to-clipboard";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import {FormattedMessage} from "react-intl";
import ContentCopyOutlined from "@mui/icons-material/ContentCopyOutlined";

type CopyClipboardProps = {
  value: string;
};

export default function CopyClipboard({value, ...other}: CopyClipboardProps) {
  const {enqueueSnackbar} = useSnackbar();
  const [state, setState] = useState({
    value,
    copied: false,
  });

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setState({value: event.target.value, copied: false});
  };

  const onCopy = () => {
    setState({...state, copied: true});
    if (state.value) {
      enqueueSnackbar(<FormattedMessage id="copiedSuccessfully" />, {variant: "success"});
    }
  };

  return (
    <TextField
      fullWidth
      value={state.value}
      onChange={handleChange}
      InputProps={{
        readOnly: true,
        endAdornment: (
          <InputAdornment position="end">
            <CopyToClipboard text={state.value} onCopy={onCopy} options={{format: "text/plain"}}>
              <Tooltip title={<FormattedMessage id="copy" />}>
                <IconButton>
                  <ContentCopyOutlined fontSize={"medium"} />
                </IconButton>
              </Tooltip>
            </CopyToClipboard>
          </InputAdornment>
        ),
      }}
      {...other}
    />
  );
}
