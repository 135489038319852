import {urls} from "../routes/urls";
import {buildUrl, UrlOptions} from "@crud-studio/react-crud-core";

const NavigationUtils = {
  getShareUrl: function (shareId: string, options?: UrlOptions): string {
    const url: string = urls.entityShareId.url.replace(":id", shareId);
    return buildUrl(url, options);
  },
};

export default NavigationUtils;
