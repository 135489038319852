import * as React from "react";
import Box from "@mui/material/Box";
import {BoxProps} from "@mui/material/Box";

export default function Logo({sx}: BoxProps) {
  return (
    <Box sx={{width: 40, height: 40, ...sx}}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1000 1000"
        height="100%"
        width="100%"
        xmlSpace="preserve"
        id="svg2"
        version="1.1"
      >
        <defs id="defs6">
          <linearGradient
            id="linearGradient28"
            gradientTransform="matrix(32.626907,-54.300262,-54.300262,-32.626907,1038.2402,310.36914)"
            gradientUnits="userSpaceOnUse"
            y2="0"
            x2="1"
            y1="0"
            x1="0"
          >
            <stop id="stop24" offset="0" stopColor="#965f26" stopOpacity="1" />
            <stop id="stop26" offset="1" stopColor="#ffcd03" stopOpacity="1" />
          </linearGradient>
          <linearGradient
            id="linearGradient48"
            spreadMethod="pad"
            gradientTransform="matrix(-17.843933,-7.2094269,-7.2094269,17.843933,1077.9746,271.66016)"
            gradientUnits="userSpaceOnUse"
            y2="0"
            x2="1"
            y1="0"
            x1="0"
          >
            <stop id="stop44" offset="0" stopColor="#965f26" stopOpacity="1" />
            <stop id="stop46" offset="1" stopColor="#ffcd03" stopOpacity="1" />
          </linearGradient>
          <linearGradient
            id="linearGradient68"
            spreadMethod="pad"
            gradientTransform="matrix(-62.67189,100.29599,100.29599,62.671905,1113.3682,216.08105)"
            gradientUnits="userSpaceOnUse"
            y2="0"
            x2="1"
            y1="0"
            x1="0"
          >
            <stop id="stop64" offset="0" stopColor="#965f26" stopOpacity="1" />
            <stop id="stop66" offset="1" stopColor="#ffcd03" stopOpacity="1" />
          </linearGradient>
          <linearGradient
            id="linearGradient88"
            spreadMethod="pad"
            gradientTransform="matrix(-3.6708527,-105.11996,-105.11996,3.6708527,1023.3437,308.71777)"
            gradientUnits="userSpaceOnUse"
            y2="0"
            x2="1"
            y1="0"
            x1="0"
          >
            <stop id="stop84" offset="0" stopColor="#965f26" stopOpacity="1" />
            <stop id="stop86" offset="1" stopColor="#ffcd03" stopOpacity="1" />
          </linearGradient>
          <linearGradient
            id="linearGradient108"
            spreadMethod="pad"
            gradientTransform="matrix(-4.565033,-43.433472,-43.433472,4.565033,1050.3242,287.81738)"
            gradientUnits="userSpaceOnUse"
            y2="0"
            x2="1"
            y1="0"
            x1="0"
          >
            <stop id="stop104" offset="0" stopColor="#965f26" stopOpacity="1" />
            <stop id="stop106" offset="1" stopColor="#ffcd03" stopOpacity="1" />
          </linearGradient>
          <linearGradient
            id="linearGradient128"
            spreadMethod="pad"
            gradientTransform="matrix(-9.6587982,-45.441147,-45.441147,9.6587982,1096.667,306.6084)"
            gradientUnits="userSpaceOnUse"
            y2="0"
            x2="1"
            y1="0"
            x1="0"
          >
            <stop id="stop124" offset="0" stopColor="#965f26" stopOpacity="1" />
            <stop id="stop126" offset="1" stopColor="#ffcd03" stopOpacity="1" />
          </linearGradient>
          <linearGradient
            id="linearGradient148"
            spreadMethod="pad"
            gradientTransform="matrix(-2.3364868,-44.583176,-44.583176,2.3364868,1018.2246,313.2793)"
            gradientUnits="userSpaceOnUse"
            y2="0"
            x2="1"
            y1="0"
            x1="0"
          >
            <stop id="stop144" offset="0" stopColor="#965f26" stopOpacity="1" />
            <stop id="stop146" offset="1" stopColor="#ffcd03" stopOpacity="1" />
          </linearGradient>
        </defs>
        <g transform="scale(9.5) rotate(180) translate(-1108 -308)" id="g10">
          <path
            id="path30"
            fill="url(#linearGradient28)"
            fillRule="evenodd"
            stroke="none"
            d="m 1071.663,269.6 9.525,24.16 h 25.149 l -33.57,-80.195 -17.244,40.302 -17.252,-40.273 -33.642,80.166 h 25.057 l 9.467,-24.217 16.221,26.089 z"
          />
          <path
            id="path50"
            fill="url(#linearGradient48)"
            fillRule="evenodd"
            stroke="none"
            d="m 1071.663,269.6 0.407,1.035 -8.146,-36.401 -8.136,19.015 0.262,41.303 z"
          />
          <path
            id="path70"
            fill="url(#linearGradient68)"
            fillRule="evenodd"
            stroke="none"
            d="m 1081.201,293.76 h 25.136 l -33.304,-79.557 z"
          />
          <path
            id="path90"
            fill="url(#linearGradient88)"
            fillRule="evenodd"
            stroke="none"
            d="m 1038.218,213.723 -33.589,80.037 h 25.057 l 0.922,-2.361 z"
          />
          <path
            id="path110"
            fill="url(#linearGradient108)"
            fillRule="evenodd"
            stroke="none"
            d="m 1055.894,253.004 -0.371,0.863 -7.728,-18.042 -8.615,33.761 16.194,26.046 0.676,-1.079 z"
          />
          <path
            id="path130"
            fill="url(#linearGradient128)"
            fillRule="evenodd"
            stroke="none"
            d="m 1081.536,293.76 h 24.801 l -10.515,-25.117 z"
          />
          <path
            id="path150"
            fill="url(#linearGradient148)"
            fillRule="evenodd"
            stroke="none"
            d="m 1015.349,268.217 -10.72,25.543 h 25.057 l 0.078,-0.199 z"
          />
        </g>
      </svg>
    </Box>
  );
}
