import {Entity, EntityFactory} from "../types/entity";
import {Device, DeviceFilters} from "../types/internal";
import DeviceForm from "../components/entity/forms/device/DeviceForm";
import {cleanEntityData, cleanSearchString} from "../helpers/EntityUtils";
import {roleAdmin} from "../constants/roles";
import {get} from "lodash";
import {currencies} from "../helpers/currencies";

export const deviceEntityId = "device";

export const deviceEntityFactory: EntityFactory<Device, DeviceFilters> = (intl): Entity<Device, DeviceFilters> => ({
  id: deviceEntityId,
  titleId: "devices",
  collection: "devices",
  persist: true,
  creatable: true,
  deletable: true,
  shareable: false,
  roles: {write: roleAdmin},
  defaultOrderBy: {field: "name", direction: "asc"},
  actions: [],
  columns: [
    {
      field: "name",
      headerNameId: "name",
      minWidth: 100,
      sortComparator: (v1, v2) => {
        return (v1 as string).localeCompare(v2 as string, undefined, {numeric: true});
      },
    },
    {
      field: "currency",
      headerNameId: "currency",
      valueFormatter: (params) => {
        return get(currencies, params.value.toUpperCase())?.name || "";
      },
      sortComparator: (v1, v2) => {
        const x1 = get(currencies, (v1 as string).toUpperCase())?.name || "";
        const x2 = get(currencies, (v2 as string).toUpperCase())?.name || "";
        return x1.localeCompare(x2, undefined, {numeric: true});
      },
    },
    {
      field: "exchangeApiKey",
      headerNameId: "exchangeApiKey",
      sortComparator: (v1, v2) => {
        return (v1 as string).localeCompare(v2 as string, undefined, {numeric: true});
      },
    },
    {
      field: "exchangeCacheSeconds",
      headerNameId: "exchangeCacheSeconds",
    },
    {
      field: "lastUpdateTime",
      headerNameId: "lastUpdateTime",
      valueFormatter: (params) => {
        try {
          return `${intl.formatDate(params.value as number)} ${intl.formatTime(params.value as number)}`;
        } catch (e) {
          return "";
        }
      },
    },
  ],
  formComponent: DeviceForm,
  getItemTitle: (item) => item.name,
  generateEmptyItem: () => ({
    id: "",
    creationTime: 0,
    lastUpdateTime: 0,
    userIds: [],
    deleted: false,
    name: "",
    description: "",
    currency: "USD",
    cacheSeconds: 600,
    exchangeApiKey: "",
    exchangeCacheSeconds: 600,
    stickerBaseUrl: `${window.origin}/`,
    stickerTemplate: "",
    stickerInchesWidth: 2.75,
    stickerInchesHeight: 1.7,
    scalePortPath: "",
    printerType: "ZPL",
    printerHost: "192.168.2.2",
    printerPort: 9100,
    weighingHeader: "",
    weighingBody: "",
    instagramUrl: "",
    webUrl: "",
    emailUrl: "",
  }),
  generateSaveItem: (item, data) => {
    return {
      ...item,
      ...cleanEntityData(data),
    };
  },
  generateCloneItem: (item) => {
    item.name = item.name + " - " + intl.formatMessage({id: "clone"});
    return item;
  },
  generateEmptyFilters: (): DeviceFilters => ({}),
  generateFullFilters: (): DeviceFilters => ({}),
  hasActiveFilters: (filters: DeviceFilters) => false,
  hasEmptyFilters: (filters: DeviceFilters) => false,
  filterItems: (items, filters) => items,
  filterDataItems: (items) => items,
  searchItems: (items, search) => {
    const cleanedSearch = cleanSearchString(search);
    if (!cleanedSearch) {
      return items;
    }
    return items.filter(
      (item) =>
        item.name.toLowerCase().includes(cleanedSearch) || !!item.description?.toLowerCase().includes(cleanedSearch)
    );
  },
});
