import * as React from "react";
import {EntityFormComponentProps} from "../../../../types/entity";
import {Device, DeviceFilters} from "../../../../types/internal";
import {Controller, useFormContext} from "react-hook-form";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import {FormattedMessage, useIntl} from "react-intl";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {get} from "lodash";
import MenuItem from "@mui/material/MenuItem";
import {currencies} from "../../../../helpers/currencies";
import {printerTypeInfoMap} from "../../../../types/enums";
import {EMAIL_REGEX, WEBSITE_REGEX} from "../../../../constants/regex";

const DeviceForm = ({entity, item, disabled}: EntityFormComponentProps<Device, DeviceFilters>) => {
  const intl = useIntl();

  const {control} = useFormContext<Device>();

  return (
    <Card>
      <CardContent>
        <Stack spacing={3}>
          {item?.id && (
            <TextField fullWidth label={intl.formatMessage({id: "id"})} type="text" disabled={true} value={item.id} />
          )}

          <Controller
            name="name"
            rules={{
              required: intl.formatMessage({id: "requiredField"}),
            }}
            control={control}
            render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
              return (
                <TextField
                  {...field}
                  inputRef={ref}
                  required
                  fullWidth
                  label={intl.formatMessage({id: "name"})}
                  type="text"
                  autoFocus={!item?.id}
                  inputProps={{
                    maxLength: 200,
                  }}
                  error={invalid}
                  helperText={error?.message}
                  disabled={disabled}
                />
              );
            }}
          />

          <Controller
            name="description"
            control={control}
            render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
              return (
                <TextField
                  {...field}
                  inputRef={ref}
                  fullWidth
                  multiline
                  rows={3}
                  label={intl.formatMessage({id: "description"})}
                  type="text"
                  inputProps={{
                    maxLength: 1000,
                  }}
                  error={invalid}
                  helperText={error?.message}
                  disabled={disabled}
                />
              );
            }}
          />

          <Controller
            name="cacheSeconds"
            rules={{
              required: intl.formatMessage({id: "requiredField"}),
            }}
            control={control}
            render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
              return (
                <TextField
                  {...field}
                  inputRef={ref}
                  required
                  fullWidth
                  label={intl.formatMessage({id: "deviceCacheSeconds"})}
                  type="number"
                  error={invalid}
                  helperText={error?.message}
                  disabled={disabled}
                />
              );
            }}
          />

          <Controller
            name="currency"
            rules={{
              required: intl.formatMessage({id: "requiredField"}),
            }}
            control={control}
            render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
              return (
                <TextField
                  {...field}
                  inputRef={ref}
                  required
                  fullWidth
                  label={intl.formatMessage({id: "currency"})}
                  error={invalid}
                  helperText={error?.message}
                  disabled={disabled}
                  select
                  value={field?.value || []}
                >
                  {Object.keys(currencies).map((c) => {
                    const currency = get(currencies, c);
                    return (
                      <MenuItem key={c} value={c}>
                        {currency.name}
                      </MenuItem>
                    );
                  })}
                </TextField>
              );
            }}
          />

          <Controller
            name="weighingHeader"
            control={control}
            render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
              return (
                <TextField
                  {...field}
                  inputRef={ref}
                  fullWidth
                  label={intl.formatMessage({id: "weighingHeader"})}
                  type="text"
                  inputProps={{
                    maxLength: 500,
                  }}
                  error={invalid}
                  helperText={error?.message}
                  disabled={disabled}
                />
              );
            }}
          />

          <Controller
            name="weighingBody"
            control={control}
            render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
              return (
                <TextField
                  {...field}
                  inputRef={ref}
                  fullWidth
                  multiline
                  minRows={3}
                  maxRows={5}
                  label={intl.formatMessage({id: "weighingBody"})}
                  type="text"
                  inputProps={{
                    maxLength: 1000,
                  }}
                  error={invalid}
                  helperText={error?.message}
                  disabled={disabled}
                />
              );
            }}
          />

          <Controller
            name="instagramUrl"
            rules={{
              pattern: {value: WEBSITE_REGEX, message: intl.formatMessage({id: "invalidUrl"})},
            }}
            control={control}
            render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
              return (
                <TextField
                  {...field}
                  inputRef={ref}
                  fullWidth
                  label={intl.formatMessage({id: "instagramUrl"})}
                  type="url"
                  inputProps={{
                    maxLength: 500,
                  }}
                  error={invalid}
                  helperText={error?.message}
                  disabled={disabled}
                />
              );
            }}
          />

          <Controller
            name="webUrl"
            rules={{
              pattern: {value: WEBSITE_REGEX, message: intl.formatMessage({id: "invalidUrl"})},
            }}
            control={control}
            render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
              return (
                <TextField
                  {...field}
                  inputRef={ref}
                  fullWidth
                  label={intl.formatMessage({id: "webUrl"})}
                  type="url"
                  inputProps={{
                    maxLength: 500,
                  }}
                  error={invalid}
                  helperText={error?.message}
                  disabled={disabled}
                />
              );
            }}
          />

          <Controller
            name="emailUrl"
            rules={{
              pattern: {value: EMAIL_REGEX, message: intl.formatMessage({id: "invalidEmail"})},
            }}
            control={control}
            render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
              return (
                <TextField
                  {...field}
                  inputRef={ref}
                  fullWidth
                  label={intl.formatMessage({id: "email"})}
                  type="email"
                  inputProps={{
                    maxLength: 500,
                  }}
                  error={invalid}
                  helperText={error?.message}
                  disabled={disabled}
                />
              );
            }}
          />

          <Controller
            name="exchangeApiKey"
            rules={{
              required: intl.formatMessage({id: "requiredField"}),
            }}
            control={control}
            render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
              return (
                <TextField
                  {...field}
                  inputRef={ref}
                  required
                  fullWidth
                  label={intl.formatMessage({id: "exchangeApiKey"})}
                  type="text"
                  inputProps={{
                    maxLength: 200,
                  }}
                  error={invalid}
                  helperText={error?.message}
                  disabled={disabled}
                />
              );
            }}
          />

          <Controller
            name="exchangeCacheSeconds"
            rules={{
              required: intl.formatMessage({id: "requiredField"}),
            }}
            control={control}
            render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
              return (
                <TextField
                  {...field}
                  inputRef={ref}
                  required
                  fullWidth
                  label={intl.formatMessage({id: "exchangeCacheSeconds"})}
                  type="number"
                  error={invalid}
                  helperText={error?.message}
                  disabled={disabled}
                />
              );
            }}
          />

          <Controller
            name="scalePortPath"
            rules={{}}
            control={control}
            render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
              return (
                <TextField
                  {...field}
                  inputRef={ref}
                  fullWidth
                  label={intl.formatMessage({id: "scalePortPath"})}
                  type="text"
                  inputProps={{
                    maxLength: 200,
                  }}
                  error={invalid}
                  helperText={error?.message}
                  disabled={disabled}
                />
              );
            }}
          />

          <Controller
            name="printerType"
            rules={{
              required: intl.formatMessage({id: "requiredField"}),
            }}
            control={control}
            render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
              return (
                <TextField
                  {...field}
                  inputRef={ref}
                  required
                  fullWidth
                  label={intl.formatMessage({id: "printerType"})}
                  error={invalid}
                  helperText={error?.message}
                  disabled={disabled}
                  select
                  value={field?.value || []}
                >
                  {Array.from(printerTypeInfoMap).map(([value, enumInfo]) => (
                    <MenuItem key={value} value={value}>
                      <FormattedMessage id={enumInfo.labelId} />
                    </MenuItem>
                  ))}
                </TextField>
              );
            }}
          />

          <Controller
            name="printerHost"
            rules={{
              required: intl.formatMessage({id: "requiredField"}),
            }}
            control={control}
            render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
              return (
                <TextField
                  {...field}
                  inputRef={ref}
                  required
                  fullWidth
                  label={intl.formatMessage({id: "printerHost"})}
                  type="text"
                  inputProps={{
                    maxLength: 50,
                  }}
                  error={invalid}
                  helperText={error?.message}
                  disabled={disabled}
                />
              );
            }}
          />

          <Controller
            name="printerPort"
            rules={{
              required: intl.formatMessage({id: "requiredField"}),
            }}
            control={control}
            render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
              return (
                <TextField
                  {...field}
                  inputRef={ref}
                  required
                  fullWidth
                  label={intl.formatMessage({id: "printerPort"})}
                  type="number"
                  error={invalid}
                  helperText={error?.message}
                  disabled={disabled}
                />
              );
            }}
          />

          <Controller
            name="stickerBaseUrl"
            rules={{
              required: intl.formatMessage({id: "requiredField"}),
            }}
            control={control}
            render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
              return (
                <TextField
                  {...field}
                  inputRef={ref}
                  required
                  fullWidth
                  label={intl.formatMessage({id: "stickerBaseUrl"})}
                  type="text"
                  inputProps={{
                    maxLength: 200,
                  }}
                  error={invalid}
                  helperText={error?.message}
                  disabled={disabled}
                />
              );
            }}
          />

          <Controller
            name="stickerInchesWidth"
            rules={{
              required: intl.formatMessage({id: "requiredField"}),
            }}
            control={control}
            render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
              return (
                <TextField
                  {...field}
                  inputRef={ref}
                  required
                  fullWidth
                  label={intl.formatMessage({id: "stickerInchesWidth"})}
                  type="number"
                  error={invalid}
                  helperText={error?.message}
                  disabled={disabled}
                />
              );
            }}
          />

          <Controller
            name="stickerInchesHeight"
            rules={{
              required: intl.formatMessage({id: "requiredField"}),
            }}
            control={control}
            render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
              return (
                <TextField
                  {...field}
                  inputRef={ref}
                  required
                  fullWidth
                  label={intl.formatMessage({id: "stickerInchesHeight"})}
                  type="number"
                  error={invalid}
                  helperText={error?.message}
                  disabled={disabled}
                />
              );
            }}
          />

          <Controller
            name="stickerTemplate"
            rules={{
              required: intl.formatMessage({id: "requiredField"}),
            }}
            control={control}
            render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
              return (
                <TextField
                  {...field}
                  inputRef={ref}
                  fullWidth
                  multiline
                  rows={24}
                  label={intl.formatMessage({id: "stickerTemplate"})}
                  type="text"
                  inputProps={{
                    maxLength: 1000,
                  }}
                  error={invalid}
                  helperText={error?.message}
                  disabled={disabled}
                />
              );
            }}
          />
        </Stack>
      </CardContent>
    </Card>
  );
};

export default DeviceForm;
