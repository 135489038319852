import * as React from "react";
import {FunctionComponent, PropsWithChildren, useCallback, useEffect, useMemo} from "react";
import useEntity from "../entity/hooks/useEntity";
import {BaseEntityRO, Entity} from "../../types/entity";
import useEntityCrud from "../entity-crud/hooks/useEntityCrud";
import {Share} from "../../types/internal";
import {useAppSelector} from "../../redux/hooks";
import {selectLoggedIn} from "../../redux/auth/selector";
import {shareEntityId} from "../../entities/share.entity";
import {useLocalStorageState} from "@crud-studio/react-crud-core";
import {localStorageKeyShares} from "../../constants/localStorageKeys";
import {isString} from "lodash";
import NavigationUtils from "../../helpers/NavigationUtils";
import {useLocation} from "react-use";

export type EntityShareContextProps = {
  generateShareUrl: <EntityRO extends BaseEntityRO>(
    entityId: Entity<EntityRO, any> | string,
    data: EntityRO
  ) => Promise<string>;
};

const EntityShareContext = React.createContext<EntityShareContextProps>(undefined!);

export interface EntityShareProviderProps extends PropsWithChildren<any> {}

type ShareCacheValue = {shareId: string; dataLastUpdateTime: number};

const EntityShareProvider: FunctionComponent<EntityShareProviderProps> = ({children}) => {
  const {getEntity} = useEntity();
  const {saveItem} = useEntityCrud();
  const {origin, pathname} = useLocation();

  const loggedIn = useAppSelector(selectLoggedIn);

  const entity = useMemo<Entity<Share, any>>(() => getEntity(shareEntityId), []);

  const [shares, setShares] = useLocalStorageState<{[key: string]: {[key: string]: ShareCacheValue}}>(
    localStorageKeyShares,
    {},
    {encrypted: false}
  );

  useEffect(() => {
    if (!loggedIn) {
      setShares({});
    }
  }, [loggedIn]);

  const getShareUrl = useCallback(
    async (shareId: string): Promise<string> => {
      const hash = NavigationUtils.getShareUrl(shareId);
      return `${origin}${pathname}#${hash}`;
    },
    [origin, pathname]
  );

  const generateShareUrl = useCallback(
    async <EntityRO extends BaseEntityRO>(
      entityId: Entity<EntityRO, any> | string,
      data: EntityRO
    ): Promise<string> => {
      if (!loggedIn) {
        return "";
      }

      const dataEntityId = isString(entityId) ? entityId : entityId.id;

      const cacheValue: ShareCacheValue | undefined = shares[dataEntityId]?.[data.id];
      if (cacheValue?.dataLastUpdateTime === data.lastUpdateTime) {
        return getShareUrl(cacheValue.shareId);
      }

      const share: Share = {
        id: "",
        creationTime: 0,
        lastUpdateTime: 0,
        userIds: [],
        deleted: false,
        entityId: dataEntityId,
        data: data,
      };

      const savedShare = await saveItem<Share, any>(entity, share);
      if (savedShare?.id) {
        setShares((currentShares) => {
          let entityShares = currentShares[dataEntityId];
          if (!entityShares) {
            entityShares = {};
          }
          entityShares[data.id] = {shareId: savedShare.id, dataLastUpdateTime: data.lastUpdateTime};
          currentShares[dataEntityId] = entityShares;
          return currentShares;
        });
        return getShareUrl(savedShare.id);
      }

      return "";
    },
    [shares, loggedIn, getShareUrl]
  );

  return <EntityShareContext.Provider value={{generateShareUrl}}>{children}</EntityShareContext.Provider>;
};

export {EntityShareContext, EntityShareProvider};
