import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {getAuth} from "firebase/auth";
import {getFirestore} from "firebase/firestore";
import {firebaseConfig} from "../constants/defaultValues";

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);

const auth = getAuth(app);
auth.useDeviceLanguage();

const firestore = getFirestore(app);

export {app, analytics, auth, firestore};
