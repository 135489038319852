import * as React from "react";
import {useCallback, useMemo, useRef, useState} from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {MIconButton} from "../../../components/@material-extend";
import locales, {LocaleInfo} from "../../../lang";
import MenuPopover from "../../../components/common/MenuPopover";
import {map} from "lodash";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {selectLocale} from "../../../redux/auth/selector";
import {changeLocale} from "../../../redux/auth/slice";
import {activeLocales} from "../../../constants/defaultValues";

export default function LanguagePopover() {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  const currentLocale = useAppSelector(selectLocale);
  const currentLocaleInfo = useMemo<LocaleInfo>(() => locales[currentLocale], [currentLocale]);

  const dispatch = useAppDispatch();

  const handleChangeLocale = useCallback((locale: string): void => {
    dispatch(changeLocale({locale: locale}));
  }, []);

  const activeLocaleInfos = useMemo<LocaleInfo[]>(
    () => map(locales, (i) => i).filter((i) => activeLocales.includes(i.id)),
    [activeLocales, locales]
  );
  const visible = useMemo<boolean>(() => activeLocaleInfos.length > 1, [activeLocaleInfos]);

  if (!visible) {
    return null;
  }

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={() => setOpen(true)}
        sx={{
          padding: 0,
        }}
      >
        <currentLocaleInfo.icon
          sx={{width: 28, height: 28, borderRadius: 3, display: "flex", alignItems: "center", justifyContent: "center"}}
        />
      </MIconButton>

      <MenuPopover open={open} onClose={() => setOpen(false)} anchorEl={anchorRef.current} arrow={true}>
        <Box sx={{py: 1}}>
          {map(activeLocaleInfos, (localeInfo) => (
            <MenuItem
              key={localeInfo.id}
              selected={localeInfo.id === currentLocale}
              onClick={() => {
                handleChangeLocale(localeInfo.id);
                setOpen(false);
              }}
              sx={{py: 1, px: 2.5}}
            >
              <ListItemIcon>
                <localeInfo.icon sx={{width: 28, height: 28, borderRadius: 3}} />
              </ListItemIcon>

              <ListItemText primaryTypographyProps={{variant: "body2"}}>{localeInfo.name}</ListItemText>
            </MenuItem>
          ))}
        </Box>
      </MenuPopover>
    </>
  );
}
