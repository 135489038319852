import {urls} from "../routes/urls";

export const underMaintenance = false;

export const defaultAppUrl = urls.home.url;
export const supportEmail = "info@AtelierBenito.com";

export const firebaseConfig = {
  apiKey: "AIzaSyAFeArbSGVE7Qto_os77f1ixc33IasMbwk",
  authDomain: "worth-in-gold.firebaseapp.com",
  projectId: "worth-in-gold",
  storageBucket: "worth-in-gold.appspot.com",
  messagingSenderId: "1013445815690",
  appId: "1:1013445815690:web:8a7ed28d7bbf42724a6c73",
  measurementId: "G-DDVZW0945B",
};

export const activeLocales = ["en"]; //, "he"];
export const defaultLocale = activeLocales[0];

const getNavigatorLanguage = (): string => {
  const navigator: any = window.navigator;
  if (navigator.languages && navigator.languages.length) {
    return navigator.languages[0];
  } else {
    return navigator.userLanguage || navigator.language || navigator.browserLanguage || defaultLocale;
  }
};

export const getUserDefaultLocale = (): string => {
  if (activeLocales.length === 1) {
    return activeLocales[0];
  }

  let navigatorLanguage = getNavigatorLanguage();
  if (navigatorLanguage && navigatorLanguage.length > 2) {
    navigatorLanguage = navigatorLanguage.substring(0, 2).toLowerCase();
    if (navigatorLanguage === "iw") {
      navigatorLanguage = "he";
    }
    if (activeLocales.includes(navigatorLanguage)) {
      return navigatorLanguage;
    }
  }

  return defaultLocale;
};
