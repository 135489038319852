import {useCallback} from "react";
import {useNavigate} from "react-router-dom";
import {useAppSelector} from "../redux/hooks";
import {selectLoggedIn} from "../redux/auth/selector";
import NiceModal from "@ebay/nice-modal-react";
import LoginDialog from "../components/dialogs/LoginDialog";

const useNavigateLoggedIn = (): ((url: string) => void) => {
  const navigate = useNavigate();

  const loggedIn = useAppSelector(selectLoggedIn);

  const navigateInternal = useCallback(
    (url: string): void => {
      if (loggedIn) {
        navigate(url);
      } else {
        NiceModal.show(LoginDialog, {redirectUrl: url});
      }
    },
    [loggedIn, navigate]
  );

  return navigateInternal;
};

export default useNavigateLoggedIn;
