import * as React from "react";
import {FunctionComponent, useMemo} from "react";
import {IntlProvider} from "react-intl";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import ThemeConfig from "./theme";
import RtlLayout from "./components/common/RtlLayout";
import GlobalStyles from "./theme/globalStyles";
import locales, {LocaleInfo} from "./lang";
import {HashRouter} from "react-router-dom";
import Router from "./routes/routes";
import {useAppDispatch, useAppSelector} from "./redux/hooks";
import {selectDarkMode, selectLocale} from "./redux/auth/selector";
import NotistackProvider from "./components/notifications/NotistackProvider";
import TitleManager from "./managers/TitleManager";
import NiceModal from "@ebay/nice-modal-react";
import {EntityProvider} from "./contexts/entity/EntityContext";
import {entityFactories} from "./entities/entityFactories";
import {EntityCrudProvider} from "./contexts/entity-crud/EntityCrudContext";
import {EntityFiltersProvider} from "./contexts/entity-filters/EntityFiltersContext";
import {useEffectOnce} from "react-use";
import {refreshUser} from "./redux/auth/slice";
import UserProfileManager from "./managers/UserProfileManager";
import {EntityShareProvider} from "./contexts/entity-share/EntityShareContext";
import {underMaintenance} from "./constants/defaultValues";
import Maintenance from "./pages/general/maintenance";

export interface AppProps {}

const App: FunctionComponent<AppProps> = ({}) => {
  const dispatch = useAppDispatch();
  const locale = useAppSelector(selectLocale);
  const currentLocale = useMemo<LocaleInfo>(() => locales[locale], [locale]);
  const darkMode = useAppSelector(selectDarkMode);

  useEffectOnce(() => {
    dispatch(refreshUser({force: false}));
  });

  return (
    <IntlProvider
      locale={currentLocale.locale}
      messages={currentLocale.messages}
      onError={(err) => {
        if (err.code === "MISSING_TRANSLATION") {
          console.warn("Missing translation", err.message);
          return;
        }
        throw err;
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={currentLocale.dateLocalization}>
        <ThemeConfig
          isDarkMode={darkMode}
          isRtl={currentLocale.direction === "rtl"}
          localization={currentLocale.materialUiLocalization}
        >
          <RtlLayout>
            <NotistackProvider>
              <GlobalStyles />
              <UserProfileManager />

              {underMaintenance ? (
                <Maintenance />
              ) : (
                <EntityProvider entityFactories={entityFactories}>
                  <EntityCrudProvider>
                    <EntityFiltersProvider>
                      <EntityShareProvider>
                        <HashRouter>
                          <NiceModal.Provider>
                            <TitleManager />
                            <Router />
                          </NiceModal.Provider>
                        </HashRouter>
                      </EntityShareProvider>
                    </EntityFiltersProvider>
                  </EntityCrudProvider>
                </EntityProvider>
              )}
            </NotistackProvider>
          </RtlLayout>
        </ThemeConfig>
      </LocalizationProvider>
    </IntlProvider>
  );
};
export default App;
