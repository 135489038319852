import enLang from "./entries/en-US";
import heLang from "./entries/he-IL";
import {ComponentType} from "react";
import {BoxProps} from "@mui/material/Box";
import {Localization} from "@mui/material/locale";
import {GridLocaleText} from "@mui/x-data-grid";

export interface LocaleInfo {
  id: string;
  locale: string;
  messages: {[key: string]: string};
  name: string;
  shortName: string;
  icon: ComponentType<BoxProps>;
  direction: "ltr" | "rtl";
  materialUiLocalization: Localization;
  dateLocalization: Locale;
  dataGridLocalization: Partial<GridLocaleText>;
}

const locales: {[key: string]: LocaleInfo} = {
  en: enLang,
  he: heLang,
};

export default locales;
