import {FunctionComponent, useEffect, useState} from "react";
import {useTitle} from "react-use";
import {urls} from "../routes/urls";
import {useIntl} from "react-intl";
import {matchPath, useLocation} from "react-router-dom";
import {findLast} from "lodash";
import useEntity from "../contexts/entity/hooks/useEntity";

interface IProps {}

const TitleManager: FunctionComponent<IProps> = () => {
  const intl = useIntl();
  const {pathname} = useLocation();
  const {getEntity} = useEntity();

  const [title, setTitle] = useState<string>(intl.formatMessage({id: "titleLong"}));
  useTitle(title);

  useEffect(() => {
    if (!pathname) {
      setTitle(intl.formatMessage({id: "titleLong"}));
      return;
    }

    const urlInfo = findLast(urls, (url) => !!matchPath({path: url.url}, pathname));
    if (!urlInfo) {
      setTitle(intl.formatMessage({id: "titleLong"}));
      return;
    }

    let title = `${intl.formatMessage({id: urlInfo.titleKey})} | ${intl.formatMessage({id: "titleShort"})}`;

    const match = matchPath({path: urlInfo.url}, pathname);
    const entityParam = match?.params?.entity;
    if (entityParam) {
      const entity = getEntity(entityParam);
      if (entity) {
        title = `${intl.formatMessage({id: entity.titleId})} - ${title}`;
      }
    }

    setTitle(title);
  }, [pathname]);

  return null;
};
export default TitleManager;
