import * as React from "react";
import {Theme} from "@mui/material/styles/createTheme";

export default function TreeView(theme: Theme) {
  return {
    MuiTreeView: {},
    MuiTreeItem: {
      styleOverrides: {
        label: {...theme.typography.body2},
        iconContainer: {width: "auto"},
      },
    },
  };
}
