import * as React from "react";
import {Theme} from "@mui/material/styles/createTheme";

const ICON_SMALL = {width: 20, height: 20};
const ICON_LARGE = {width: 28, height: 28};

export default function Rating(theme: Theme) {
  return {
    MuiRating: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            opacity: 0.48,
          },
        },
        iconEmpty: {color: theme.palette.grey[500_48]},
        sizeSmall: {"& svg": {...ICON_SMALL}},
        sizeLarge: {"& svg": {...ICON_LARGE}},
      },
    },
  };
}
