import {useCallback} from "react";
import {Entity} from "../types/entity";
import {buildUrl} from "@crud-studio/react-crud-core";
import {urls} from "../routes/urls";
import {isString} from "lodash";
import useEntity from "../contexts/entity/hooks/useEntity";
import {useAppSelector} from "../redux/hooks";
import {selectUserId} from "../redux/auth/selector";
import {publicUserId} from "../contexts/entity-crud/EntityCrudContext";
import {urlParamUserId} from "../constants/urlParams";

const useEntityNavigation = () => {
  const {getEntity} = useEntity();

  const userId = useAppSelector(selectUserId);

  const getEntityGridUrl = useCallback(
    (entityId: Entity<any, any> | string, user: boolean | string): string => {
      const entityUserId: string = isString(user) ? user : user ? userId : publicUserId;
      const url: string = urls.entityGrid.url.replace(":entity", isString(entityId) ? entityId : entityId.id);
      return buildUrl(url, {queryStringParams: {[urlParamUserId]: entityUserId}});
    },
    [userId]
  );

  const getEntityCreateUrl = useCallback(
    (entityId: Entity<any, any> | string, user: boolean | string): string => {
      const entityUserId: string = isString(user) ? user : user ? userId : publicUserId;
      const url: string = urls.entityDetails.url.replace(":entity", isString(entityId) ? entityId : entityId.id);
      return buildUrl(url, {queryStringParams: {[urlParamUserId]: entityUserId}});
    },
    [userId]
  );

  const getEntityDetailsUrl = useCallback(
    (entityId: Entity<any, any> | string, id: string): string => {
      const entity = getEntity(entityId);
      const url: string = entity.getItemDetailsUrl
        ? entity.getItemDetailsUrl(id)
        : urls.entityDetailsId.url.replace(":entity", isString(entityId) ? entityId : entityId.id).replace(":id", id);
      return buildUrl(url);
    },
    [getEntity, getEntityGridUrl]
  );

  return {getEntityGridUrl, getEntityCreateUrl, getEntityDetailsUrl};
};

export default useEntityNavigation;
