import * as React from "react";
import {useMemo, ReactNode} from "react";
import CssBaseline from "@mui/material/CssBaseline";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import createTheme, {ThemeOptions} from "@mui/material/styles/createTheme";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import shape from "./shape";
import palette from "./palette";
import typography from "./typography";
import breakpoints from "./breakpoints";
import componentsOverride from "./overrides";
import shadows, {customShadows} from "./shadows";
import {enUS, Localization} from "@mui/material/locale";
import "@mui/lab/themeAugmentation";

type ThemeConfigProps = {
  isDarkMode?: boolean;
  isRtl?: boolean;
  localization?: Localization;
  children: ReactNode;
};

export default function ThemeConfig({isDarkMode, isRtl, localization, children}: ThemeConfigProps) {
  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: isDarkMode ? {...palette.dark, mode: "dark"} : {...palette.light, mode: "light"},
      shape,
      typography,
      breakpoints,
      direction: isRtl ? "rtl" : "ltr",
      shadows: isDarkMode ? shadows.dark : shadows.light,
      customShadows: isDarkMode ? customShadows.dark : customShadows.light,
    }),
    [isDarkMode, isRtl]
  );

  const theme = createTheme(themeOptions, localization || enUS);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
