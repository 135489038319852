import * as React from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {FunctionComponent} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import DialogTitleEnhanced from "./DialogTitleEnhanced";
import NiceModal, {NiceModalHocProps, useModal} from "@ebay/nice-modal-react";
import {useSnackbar} from "notistack";
import {Controller, useForm} from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import {updateUserProfile} from "../../redux/auth/slice";
import {selectError, selectLoading, selectSuccess} from "../../redux/ui/selector";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {EMAIL_REGEX} from "../../constants/regex";
import {useUpdateEffect} from "react-use";
import {selectUser, selectUserProfile} from "../../redux/auth/selector";

export type UserProfileDialogProps = {};

type FormValues = {
  email: string;
  fullName: string;
};

const UserProfileDialog: FunctionComponent<UserProfileDialogProps & NiceModalHocProps> = NiceModal.create(
  ({}: UserProfileDialogProps) => {
    const modal = useModal();
    const intl = useIntl();
    const {enqueueSnackbar} = useSnackbar();

    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUser);
    const userProfile = useAppSelector(selectUserProfile);
    const loading = useAppSelector((state) => selectLoading(state, updateUserProfile.type));
    const updateSuccess = useAppSelector((state) => selectSuccess(state, updateUserProfile.type));
    const updateError = useAppSelector((state) => selectError(state, updateUserProfile.type));

    const {handleSubmit, control} = useForm<FormValues>({
      mode: "onSubmit",
      reValidateMode: "onChange",
    });

    const onSubmit = handleSubmit((data): void => {
      dispatch(updateUserProfile({userProfile: {fullName: data.fullName}}));
    });

    useUpdateEffect(() => {
      if (updateSuccess) {
        enqueueSnackbar(<FormattedMessage id="savedSuccessfully" />, {variant: "success"});
        // modal.hide();
      }
    }, [updateSuccess]);

    useUpdateEffect(() => {
      if (updateError) {
        enqueueSnackbar(<FormattedMessage id="networkError" />, {variant: "error"});
      }
    }, [updateError]);

    return (
      <Dialog
        open={modal.visible}
        onClose={() => modal.hide()}
        TransitionProps={{
          onExited: () => modal.remove(),
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitleEnhanced onClose={modal.hide}>
          <FormattedMessage id={"myAccount"} />
        </DialogTitleEnhanced>
        <DialogContent>
          <form autoComplete="off" noValidate onSubmit={onSubmit}>
            <Stack spacing={3}>
              <Controller
                name="email"
                rules={{
                  required: intl.formatMessage({id: "requiredField"}),
                  pattern: {value: EMAIL_REGEX, message: intl.formatMessage({id: "invalidEmail"})},
                }}
                control={control}
                defaultValue={user?.email || undefined}
                render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
                  return (
                    <TextField
                      {...field}
                      inputRef={ref}
                      required
                      fullWidth
                      label={intl.formatMessage({id: "email"})}
                      type="email"
                      inputProps={{
                        maxLength: 50,
                      }}
                      error={invalid}
                      helperText={error?.message}
                      disabled={true}
                    />
                  );
                }}
              />

              <Controller
                name="fullName"
                rules={{
                  required: intl.formatMessage({id: "requiredField"}),
                }}
                control={control}
                defaultValue={userProfile?.fullName || ""}
                render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
                  return (
                    <TextField
                      {...field}
                      inputRef={ref}
                      required
                      fullWidth
                      label={intl.formatMessage({id: "fullName"})}
                      type="text"
                      inputProps={{
                        maxLength: 50,
                      }}
                      error={invalid}
                      helperText={error?.message}
                    />
                  );
                }}
              />
            </Stack>
          </form>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={modal.hide}>
            <FormattedMessage id="close" />
          </Button>
          <LoadingButton variant="contained" color="primary" onClick={onSubmit} loading={loading}>
            <FormattedMessage id="save" />
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }
);

export default UserProfileDialog;
