import * as React from "react";
import {Outlet} from "react-router-dom";
import styled from "@mui/material/styles/styled";
import useTheme from "@mui/material/styles/useTheme";
import AppNavbar from "./AppNavbar";
import {NAVBAR_DESKTOP_HEIGHT, NAVBAR_MOBILE_HEIGHT} from "../../constants/ui";

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const MainStyle = styled("div")(({theme}) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: NAVBAR_MOBILE_HEIGHT,
  paddingBottom: theme.spacing(5),
  [theme.breakpoints.up("lg")]: {
    paddingTop: NAVBAR_DESKTOP_HEIGHT,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

type AppLayoutProps = {};

export default function AppLayout({}: AppLayoutProps) {
  const theme = useTheme();

  return (
    <RootStyle>
      <AppNavbar />
      <MainStyle
        sx={{
          transition: theme.transitions.create("margin", {
            duration: theme.transitions.duration.complex,
          }),
        }}
      >
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
