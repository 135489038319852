import enMessages from "../locales/en_US";
import heMessages from "../locales/he_IL";
import {heIL} from "@mui/material/locale";
import heLocale from "date-fns/locale/he";
import FlagHebrew from "../../components/svg/flags/FlagHebrew";
import {heIL as dataGridHeIl} from "@mui/x-data-grid";
import {LocaleInfo} from "../index";

const HeLang: LocaleInfo = {
  id: "he",
  locale: "he-IL",
  messages: {
    ...enMessages,
    ...heMessages,
  },
  name: "עברית",
  shortName: "עב",
  icon: FlagHebrew,
  direction: "rtl",
  materialUiLocalization: heIL,
  dateLocalization: heLocale,
  dataGridLocalization: dataGridHeIl.components.MuiDataGrid.defaultProps.localeText,
};
export default HeLang;
