export default {
  brandName: "WiG",
  titleLong: "WiG | Worth More Than Weight in Gold",
  titleShort: "WiG",
  homepage: "Home",
  contactSupportSubject: "WiG Help",
  welcomeToPlugin: "You are Worth More Than Your Weight in Gold",
  search: "Search",
  noResultsFound: "No Results Found",
  searchNoResultsHelp: "Search is performed by the title and keywords of terms. Try to change/shorten your search.",
  filter: "Filter",
  filters: "Filters",
  categories: "Categories",
  medicalFields: "Medical Fields",
  errorPageNotFound: "Sorry, page not found!",
  errorPageNotFoundExplanation:
    "Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling or contact support.",
  errorLoadingData: "Error loading data.",
  goToHome: "Go To Home",
  contactUs: "Contact Us",
  contactSupport: "Contact Support",
  clear: "Clear",
  clearFilters: "Clear Filters",
  hasFilteredResults: "Some results are filtered and not displayed",
  lightMode: "Light Mode",
  darkMode: "Dark Mode",
  intakes: "Intakes",
  newIntakeTitle: "Create New Intake",
  newIntakeDescription: "Short wizard to generate intake tailor made to your needs.",
  newIntakePrep: "Creating new intake for Prep:",
  newIntake: "New Intake",
  createIntake: "Create Intake",
  copyIntake: "Copy Intake",
  printIntake: "Print Intake",
  validateIntake: "Validate Intake",
  applyRelevantFilters: "Apply relevant filters.",
  applyRelevantFiltersExplanation:
    "Applies the relevant filters for the selected medical field. You can always change them later.",
  clearDocumentText: "Clear document text.",
  clearDocumentTextExplanation:
    "Deletes the current text in the document. Uncheck to add the intake after the current text.",
  selectMedicalFieldToContinue: "Select medical field to continue.",
  continue: "Continue",
  cancel: "Cancel",
  menu: "Menu",
  support: "Support",
  supportContactUs: "Please feel free to contact us for any question/request/issue at:",
  supportAdditionalUrls:
    "In case the site is not loading properly in a hospital secured network you can also try one of the following urls:",
  supportAdditionalUrlsNotWorking:
    "If non of the urls are working the hospital IT department must be contacted to approve the site.",
  share: "Share",
  shareWithTheWorld: "Share WiG with the World",
  close: "Close",
  copy: "Copy",
  copiedSuccessfully: "Copied successfully.",
  print: "Print",
  download: "Download",
  downloadAddIn: "Download WiG plugin for Word",
  whatIsTheTool:
    "WiG is an art exhibit showing people their true worth. It is using state of the art technology to show anyone their real time worth in gold converted to any currency.",
  getThePlugin: "Get The Plugin",
  officeWordPlugin: "Office Word Plugin",
  pluginExplanation:
    "Download the WiG Word plugin for free and accelerate your work. The plugin finds relevant keywords in your document and lets you add the required information within seconds. No more remembering and typing again and again and again...",
  downloadForFree: "Download For Free",
  goToSearch: "Go To Search",
  knowledgeBase: "Knowledge Base",
  searchKnowledgeBase: "Search Knowledge Base",
  searchKnowledgeBaseExplanation:
    "Tired of remembering everything? Use WiG knowledge base to get all the required info with a few clicks. Something missing? Build your own knowledge base and have it available anywhere/anytime.",
  advancedTemplates: "Advanced Templates",
  advancedTemplatesExplanation:
    "Use WiG advanced templates to kick-off your intakes and reduce the work time required to complete an intake. Add your own personal templates tailor made for your exact needs.",
  supportExplanation:
    "Got an issue? We are here for you. Want more information about the exhibit? Let us know! Need help with something? Contact us anytime.",
  validate: "Validate",
  searchFillCharacters: "Search for fill characters ({character}).",
  fillCharactersFound: "Fill characters found ({character}).",
  comingSoon: "Coming Soon",
  comingSoonExplanation: "The plugin is in the final approval steps and will be available soon. Stay tuned!",
  login: "Login",
  logout: "Logout",
  loginSuccess: "Logged in to the system successfully.",
  logoutSuccess: "Logged out from the system successfully.",
  loginFailed: "Connection failed. Please check your email/password.",
  logoutFailed: "Logout failed.",
  manage: "Manage",
  title: "Title",
  description: "Description",
  tooltip: "Tooltip",
  category: "Category",
  status: "Status",
  addNew: "Add New",
  details: "Details",
  save: "Save",
  requiredField: "Required field.",
  keywords: "Keywords",
  commaSeparatedValues: "Comma separated values.",
  selectNonForAll: "If no value is selected the item will be available for all values.",
  type: "Type",
  text: "Text",
  stages: "Stages",
  addNewStage: "Add Stage",
  removeStage: "Remove Stage",
  clauses: "Clauses",
  addNewClause: "Add Clause",
  removeClause: "Remove Clause",
  checkedByDefault: "Checked By Default",
  email: "Email",
  password: "Password",
  invalidEmail: "Invalid email.",
  invalidPassword: "Invalid password.",
  invalidUrl: "Invalid URL.",
  username: "Username",
  signUp: "Sign Up",
  signUpOrLogin: "Sign Up / Login",
  doNotHaveAccount: "Don't have an account? Sign Up",
  alreadyHaveAccount: "Already have an account? Login",
  creationTime: "Creation Time",
  lastUpdateTime: "Last Update Time",
  savedSuccessfully: "Saved successfully.",
  formInvalidValues: "Some fields have invalid values.",
  clearSearch: "Clear Search",
  networkError: "Network error. Please try again later.",
  reachedMaximumAllowed: "You have reached the maximum allowed ({max}).",
  prep: "Prep",
  preps: "Preps",
  prepEmpty: "Prep is empty.",
  addToPrep: "Add To Prep",
  addToPrepSuccess: "Added to prep successfully.",
  addToPrepSignUp: "Sign up to start prepping.",
  addToPrepNoClausesSelected: "No clauses selected.",
  addToPrepLoading: "Loading prep. Please try again in a few moments.",
  cannotUpdatePrepWhileInPage:
    "Cannot update the Prep while in its page. Use the form or navigate to a different page to update it.",
  new: "New",
  open: "Open",
  showAll: "Show All",
  comments: "Comments",
  back: "Back",
  acceptPrivacyAndTerms: "By signing up, I agree to the WiG {privacyPolicy} and {termsOfService}.",
  privacyPolicy: "Privacy Policy",
  termsOfService: "Terms of Service",
  select: "Select",
  savePrepToSelect: "Save prep to select it.",
  prepSelectedSuccessfully: "Prep selected successfully.",
  templates: "Templates",
  manageTemplates: "Manage Templates",
  sections: "Sections",
  addNewSection: "Add Section",
  contents: "Contents",
  addNewContent: "Add Content",
  example: "Example",
  assistNotes: "Assist Notes",
  extendedFormat: "Extended Format",
  medicalRecordFormat: "Medical record format",
  remove: "Remove",
  item: "Item",
  items: "Items",
  addNewItem: "Add Item",
  rows: "Rows",
  columns: "Columns",
  cell: "Cell",
  selectMedicalField: "Select medical field",
  selectTemplate: "Select template",
  templateSettings: "Template Settings",
  lastStep: "Last Step",
  finish: "Finish",
  noTemplatesFound: "No templates found :( We are working on adding additional templates.",
  addExamples: "Add examples.",
  addAssistNotes: "Add assist notes.",
  addExtendedFormat: "Extended format.",
  addMedicalRecordFormat: "Medical record format.",
  addExamplesExplanation: "Show examples in relevant sections.",
  addAssistNotesExplanation: "Add assist notes for hospital employees.",
  addExtendedFormatExplanation: "Extended format for medical students.",
  addMedicalRecordFormatExplanation: "Simple format without styling ready to copy into healthcare systems.",
  keywordsFinderSettings: "Document Scanning Settings",
  keywordsFinderSettingsSignUp: "Sign up to update default settings.",
  negateKeyword: "Negate Word",
  negateKeywords: "Negate Words",
  negateKeywordsExplanation:
    'The configured words cancel term keywords that appear after. For example: "not headache" will cancel the "headache" keyword.',
  negateKeywordTypeWordExplanation: "Cancels the keyword directly after the negate word.",
  negateKeywordTypeSentenceExplanation: "Cancels the entire sentence after the negate word.",
  restoreDefaults: "Restore Defaults",
  private: "Personal",
  error: "Error",
  refresh: "Refresh",
  refreshing: "Refreshing...",
  rememberMe: "Remember me",
  rememberMeExplanation:
    "If this option is checked, your account will stay logged in after you close the window. For your own safety, uncheck in public computers.",
  delete: "Delete",
  clone: "Clone",
  clonedSuccessfully: "Cloned successfully.",
  deletedSuccessfully: "Deleted successfully",
  shareFailed: "Sharing failed. Please try again later.",
  addToMyAccount: "Add To My Account",
  addToAll: "Add To All",
  addToDocument: "Add To Document",
  addToStage: "Add To Stage",
  actions: "Actions",
  prepAddToStagesSuccessfully: "Prep added to relevant stages successfully.",
  addPrepToStagesExplanation:
    "Adds the Prep stages to the relevant stages in the document. If a stage is not found it will be added to the end of the document.",
  addPrepToDocumentExplanation: "Adds the full Prep to the document.",
  copyPrepExplanation: "Copies the full Prep to clipboard.",
  selectPrepExplanation: "Selects the Prep as the active Prep available at the top menu.",
  intakeCreatedSuccessfully: "Intake created successfully",
  subClause: "Sub Clause",
  subClauseCancel: "Cancel Sub Clause",
  addedToPrep: "Added To Prep",
  fullName: "Full Name",
  myAccount: "My Account",
  published: "Published",
  publish: "Publish",
  rejected: "Rejected",
  processing: "Processing",
  notAvailable: "Not Available",
  publishEntityApprovedExplanation: "Your submission has been approved!",
  publishEntityApprovedReason: "Amazing work! Thank you for being an important part of the WiG community.",
  publishEntityPublishExplanation:
    "Submit your work to be available for all and help make the world a better place :) The submission will be reviewed and if found eligible will be available for all.",
  publishEntityRejectedExplanation:
    "The submission was rejected due to the following error. You can always fix the error and resubmit:",
  publishEntityRejectedReason: "Invalid submission.",
  publishEntityWaitingForApprovalExplanation: "Your submission is waiting to be reviewed.",
  publishNameRequired: "You are required to update your name in order to publish.",
  updateName: "Update Name",
  publishAnonymously: "Publish anonymously",
  publishAnonymouslyExplanation:
    "Your work will appear in the system with your name next tp it as appropriate for the hard work you put into it. If for any reason you prefer your name not to appear check the box.",
  publishExplanation:
    "Please note: Once your work is published our editors can work on it with you to improve it. You can also keep updating and improving it at all time.",
  publishRequestSuccess: "Publish request sent successfully.",
  managePublishRequests: "Manage Publish Requests",
  publishRequests: "Publish Requests",
  approve: "Approve",
  reject: "Reject",
  underMaintenance: "Under Maintenance",
  underMaintenanceGetBackSoon: "We will be back soon better and stronger.",
  confirmDelete: "Confirm Delete",
  confirmDeleteExplanation: "Are your sure you want to delete?",
  edit: "Edit",
  editRequests: "Edit Requests",
  editRequestSuccess: "Edit request sent successfully.",
  editNameRequired: "You are required to update your name for edit request.",
  sendEditRequest: "Send Edit Request",
  manageEditRequests: "Manage Edit Requests",
  submit: "Submit",
  editRequestsApprovedExplanation: "The following edit requests has been approved! You can start edit right now:",
  devices: "Devices",
  manageDevices: "Manage Devices",
  name: "Name",
  learnMore: "Learn More",
  currency: "Currency",
  weighing: "Weighing",
  weighings: "Weighings",
  weighingTest: "Weighing Test",
  worth: "Worth",
  exchangePrice: "Exchange Price",
  deviceName: "Device Name",
  deviceCacheSeconds: "Device Cache Seconds",
  shareWeighing: "Share Weighing",
  weighingTitle: "I Am Worth More Than My Weight in Gold",
  weighingFooter: '"You Are Worth More Than Your Weight in Gold",\nan artwork by Benito Esquenazi',
  weighingVisitDate: "You have visited us at {device} on {date}.",
  exchangeApiKey: "Exchange API Key",
  exchangeCacheSeconds: "Exchange Cache Seconds",
  stickerBaseUrl: "Sticker Base URL",
  stickerTemplate: "Sticker Template",
  stickerInchesWidth: "Sticker Width (Inches)",
  stickerInchesHeight: "Sticker Height (Inches)",
  weightInKilos: "Weight In Kilos",
  weightInPounds: "Weight In Pounds",
  scalePortPath: "Scale Port Path",
  printerHost: "Printer Host",
  printerPort: "Printer Port",
  printerType: "Printer Type",
  printerTypeZPL: "ZPL",
  printerTypeImage: "Image",
  id: "ID",
  weighingHeader: "Weighing Header",
  weighingBody: "Weighing Body",
  instagramUrl: "Instagram URL",
  webUrl: "Web URL",
  saveWeighingToShare: "Save weighing in order to share.",
  device: "Device",
  theWigYouDigitalLabel: "The WiG - Your Digital Label",
  takeScreenshotAndPost: "Take Screenshot & Post to Your Social",
};
