import enMessages from "../locales/en_US";
import {enUS} from "@mui/material/locale";
import enLocale from "date-fns/locale/en-US";
import FlagEnglish from "../../components/svg/flags/FlagEnglish";
import {enUS as dataGridEnUs} from "@mui/x-data-grid";
import {LocaleInfo} from "../index";

const EnLang: LocaleInfo = {
  id: "en",
  locale: "en-US",
  messages: {
    ...enMessages,
  },
  name: "English",
  shortName: "EN",
  icon: FlagEnglish,
  direction: "ltr",
  materialUiLocalization: enUS,
  dateLocalization: enLocale,
  dataGridLocalization: dataGridEnUs.components.MuiDataGrid.defaultProps.localeText,
};
export default EnLang;
