import * as React from "react";
import {ReactNode} from "react";
import {Navigate, useParams} from "react-router-dom";
import {urls} from "../urls";
import useEntity from "../../contexts/entity/hooks/useEntity";
import {EntityAccessType} from "../../types/entity";

type EntityGuardProps = {
  accessType?: EntityAccessType;
  children: ReactNode;
};

export default function EntityGuard({accessType, children}: EntityGuardProps) {
  const {hasEntityAccess} = useEntity();
  const params = useParams();

  if (!params.entity || !hasEntityAccess(params.entity, accessType || "read")) {
    return <Navigate to={urls.error.url} />;
  }

  return <>{children}</>;
}
