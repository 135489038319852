import * as React from "react";
import {FunctionComponent} from "react";
import configureAppStore from "./redux/store";
import {PersistGate} from "redux-persist/integration/react";
import LoadingCenter from "./components/common/LoadingCenter";
import {Provider as ReduxProvider} from "react-redux";
import App from "./App";

import "simplebar/src/simplebar.css";

const {store, persistor} = configureAppStore();
export type AppDispatch = typeof store.dispatch;

export interface AppContainerProps {}

const AppContainer: FunctionComponent<AppContainerProps> = ({}) => {
  return (
    <ReduxProvider store={store}>
      <PersistGate loading={<LoadingCenter />} persistor={persistor}>
        <App />
      </PersistGate>
    </ReduxProvider>
  );
};
export default AppContainer;
