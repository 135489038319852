import {Theme} from "@mui/material/styles/createTheme";
import {Components} from "@mui/material/styles/components";

// ----------------------------------------------------------------------

export default function Lists(theme: Theme): Components {
  return {
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          width: 20,
          height: 20,
          color: "inherit",
          minWidth: "auto!important",
          marginRight: theme.spacing(2),
        },
      },
    },
    MuiListItemAvatar: {
      styleOverrides: {
        root: {
          minWidth: "auto",
          marginRight: theme.spacing(2),
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          fontSize: "medium",
          marginTop: 0,
          marginBottom: 0,
        },
        multiline: {
          marginTop: 0,
          marginBottom: 0,
        },
      },
    },
  };
}
