import {BaseEntityRO, Entity} from "../types/entity";
import {get, isNil, omit, toString, trim} from "lodash";
import {omitByDeep} from "./LodashUtils";

export const sortEntityItems = <EntityRO extends BaseEntityRO, FiltersRO>(
  entity: Entity<EntityRO, FiltersRO>,
  items: EntityRO[]
): EntityRO[] => {
  return items.sort((a, b) => {
    const aStr = toString(get(a, entity.defaultOrderBy.field));
    const bStr = toString(get(b, entity.defaultOrderBy.field));
    return entity.defaultOrderBy.direction === "asc"
      ? aStr.localeCompare(bStr, undefined, {numeric: true})
      : bStr.localeCompare(aStr, undefined, {numeric: true});
  });
};

export const cleanEntityData = <EntityRO extends BaseEntityRO>(data: Partial<EntityRO>): Partial<EntityRO> => {
  return omitByDeep(
    omit(data, [
      "id",
      "creationTime",
      "lastUpdateTime",
      "userIds",
      "publishStatus",
      "publishReason",
      "publishRequestId",
      "publishFullName",
      "publishTime",
      "publishUserId",
    ]),
    isNil
  );
};

export const cleanSearchString = (search: string): string => {
  return trim(search).toLowerCase();
};

export const getInsertIndexByEnum = (
  enumType: {[key: string]: string},
  newEnumKey: string,
  currentEnumKeys: string[]
): number => {
  let insertIndex = 0;
  const enumKeys = Object.keys(enumType);
  const newEnumKeyOrdinal = enumKeys.indexOf(newEnumKey);
  currentEnumKeys.forEach((currentEnumKey, index) => {
    const currentOrdinal = enumKeys.indexOf(currentEnumKey);
    if (currentOrdinal <= newEnumKeyOrdinal) {
      insertIndex = index + 1;
    }
  });
  return insertIndex;
};
