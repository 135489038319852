import {useContext} from "react";
import {EntityCrudContext, EntityCrudContextProps} from "../EntityCrudContext";

const useEntityCrud = (): EntityCrudContextProps => {
  const context = useContext(EntityCrudContext);

  if (!context) throw new Error("EntityCrudContext must be used inside EntityCrudProvider");

  return context;
};
export default useEntityCrud;
