import {defaultLocale} from "../../constants/defaultValues";
import {RootState} from "../store";
import {User} from "firebase/auth";
import {UserProfile} from "../../types/internal";

export const selectUser = (state: RootState): User | undefined => state.auth.user;

export const selectUserId = (state: RootState): string => state.auth.user?.uid || "";

export const selectUserProfile = (state: RootState): UserProfile | undefined => state.auth.userProfile;

export const selectRole = (state: RootState): string | undefined => state.auth.role;

export const selectLocale = (state: RootState): string => state.auth.locale || defaultLocale;

export const selectLoggedIn = (state: RootState): boolean => state.auth.loggedIn;

export const selectDarkMode = (state: RootState): boolean => state.auth.darkMode;

export const selectLastRefreshTime = (state: RootState): number => state.auth.lastRefreshTime;
