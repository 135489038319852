import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {getUserDefaultLocale} from "../../constants/defaultValues";
import {User} from "firebase/auth";
import {UserProfile} from "../../types/internal";

export const authSliceName = "auth";

type AuthState = {
  user?: User;
  userProfile?: UserProfile;
  role?: string;
  loggedIn: boolean;
  locale: string;
  darkMode: boolean;
  lastRefreshTime: number;
};

const initialState: AuthState = {
  user: undefined,
  userProfile: undefined,
  role: undefined,
  loggedIn: false,
  locale: getUserDefaultLocale(),
  darkMode: false,
  lastRefreshTime: 0,
};

export const authSlice = createSlice({
  name: authSliceName,
  initialState: initialState,
  reducers: {
    authenticateEmailPassword: (
      state,
      action: PayloadAction<{
        email: string;
        password: string;
        rememberMe?: boolean;
      }>
    ) => {
      return {...state};
    },
    authenticateSuccess: (
      state,
      action: PayloadAction<{
        user: User;
        userProfile?: UserProfile;
        role?: string;
      }>
    ) => {
      return {
        ...state,
        user: action.payload.user,
        userProfile: action.payload.userProfile,
        role: action.payload.role,
        loggedIn: true,
        lastRefreshTime: new Date().getTime(),
      };
    },
    authenticateError: (state) => {
      return {...state};
    },
    refreshUser: (state, action: PayloadAction<{force: boolean}>) => {
      return {...state};
    },
    updateUserProfile: (state, action: PayloadAction<{userProfile: Partial<UserProfile>}>) => {
      return {...state};
    },
    updateUserProfileSuccess: (state, action: PayloadAction<{userProfile: UserProfile}>) => {
      return {...state, userProfile: action.payload.userProfile};
    },
    updateUserProfileError: (state) => {
      return {...state};
    },
    changeLocale: (state, action: PayloadAction<{locale: string}>) => {
      return {...state, locale: action.payload.locale};
    },
    toggleDarkMode: (state) => {
      return {...state, darkMode: !state.darkMode};
    },
    logout: (state) => {
      return {...state};
    },
    logoutSuccess: (state) => {
      return {
        ...state,
        user: undefined,
        userProfile: undefined,
        role: undefined,
        loggedIn: false,
        lastRefreshTime: 0,
      };
    },
    logoutError: (state) => {
      return {...state};
    },
  },
});

export const {
  authenticateEmailPassword,
  authenticateSuccess,
  authenticateError,
  refreshUser,
  updateUserProfile,
  updateUserProfileSuccess,
  updateUserProfileError,
  changeLocale,
  toggleDarkMode,
  logout,
  logoutSuccess,
  logoutError,
} = authSlice.actions;

export default authSlice.reducer;
