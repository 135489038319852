import * as React from "react";
import {ReactNode} from "react";
import {useAppSelector} from "../../redux/hooks";
import {selectLoggedIn} from "../../redux/auth/selector";
import {Navigate, useLocation} from "react-router-dom";
import {defaultAppUrl} from "../../constants/defaultValues";

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({children}: AuthGuardProps) {
  const loggedIn = useAppSelector(selectLoggedIn);
  const {pathname} = useLocation();

  if (!loggedIn) {
    return <Navigate to={defaultAppUrl} state={{origin: pathname}} />;
  }

  return <>{children}</>;
}
